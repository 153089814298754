<!-- registration component start -->

<ng-container>
  <!-- header start -->
  <app-header>
    <app-language-menu (onLanguageChange)="vmOnLanguageChange($event)"></app-language-menu>
  </app-header>
  <!-- header end -->

  <!-- registration section start -->
  <div class="registration-section">
    <!-- main container start -->
    <div class="main-container">
      <!-- registration form start -->
      <form #registrationForm="ngForm" class="registration-form" (submit)="submit(registrationForm)"
        *ngIf="!registrationSuccessful">

        <!-- field start -->
        <div class="field field-tips">
          <label for="companyName">{{'registration.form.fields.companyName' | translate}}:</label>

          <!-- input-wrapper start -->
          <div class="input-wrapper">
            <span class="tips">{{'registration.form.fields.companyTip' | translate}}</span>
            <input type="text" name="companyName" id="companyName" [(ngModel)]="formCredentials.companyName"
              (keydown)="preventEnterKeyTigger($event)" required>
          </div>
          <!-- input-wrapper end -->

        </div>
        <!-- field end -->

        <!-- field start -->
        <div class="field field-tips">
          <label for="vatNumber">{{'registration.form.fields.vatNumber' | translate}}:</label>

          <!-- input-wrapper start -->
          <div class="input-wrapper">
            <span class="tips">Please use the correct format for your selected country</span>
            <input type="text" name="vatNumber" id="vatNumber" [(ngModel)]="formCredentials.vatNumber"
              (focusout)="vmOnVatEnter($event)" (keyup.enter)="vmOnVatEnter($event)"
              (keydown)="preventEnterKeyTigger($event)" required>
          </div>
          <!-- input-wrapper end -->
        </div>
        <!-- field end -->

        <!-- field start -->
        <div class="field">
          <label for="country">{{'registration.form.fields.country' | translate}}:</label>
          <select id="country" name="country" [(ngModel)]="formCredentials.country"
            (keydown)="preventEnterKeyTigger($event)" required>
            <option [ngValue]="null"></option>
            <option *ngFor="let country of countries" [ngValue]="country.countryId">{{country.title}}</option>
          </select>
        </div>
        <!-- field end -->

        <!-- field start -->
        <div class="field">
          <label for="city">{{'registration.form.fields.city' | translate}}:</label>
          <input type="text" name="city" id="city" [(ngModel)]="formCredentials.city"
            (keydown)="preventEnterKeyTigger($event)" required>
        </div>
        <!-- field end -->

        <!-- field start -->
        <div class="field">
          <label for="postcode">{{'registration.form.fields.postcode' | translate}}:</label>
          <input type="text" name="postcode" id="postcode" [(ngModel)]="formCredentials.postcode"
            (keydown)="preventEnterKeyTigger($event)" required>
        </div>
        <!-- field end -->

        <!-- field start -->
        <div class="field">
          <label for="address">{{'registration.form.fields.address' | translate}}:</label>
          <input type="text" name="address" id="address" [(ngModel)]="formCredentials.address"
            (keydown)="preventEnterKeyTigger($event)" required>
        </div>
        <!-- field end -->

        <!-- field start -->
        <div class="field">
          <label for="webAddress">{{'registration.form.fields.webAddress' | translate}}:</label>
          <input type="text" name="webAddress" id="webAddress" [(ngModel)]="formCredentials.webAddress"
            (keydown)="preventEnterKeyTigger($event)" required>
        </div>
        <!-- field end -->

        <!-- field start -->
        <div class="field">
          <label for="market">{{'registration.form.fields.markets' | translate}}:</label>
          <select id="market" name="market" [(ngModel)]="formCredentials.market"
            (keydown)="preventEnterKeyTigger($event)" required>
            <option [ngValue]="null"></option>
            <option *ngFor="let market of marketsList[lang]" [ngValue]="market">{{market}}</option>
          </select>
        </div>
        <!-- field end -->

        <!-- field start -->
        <div class="field">
          <label for="firstName">{{'registration.form.fields.firstName' | translate}}:</label>
          <input type="text" name="firstName" id="firstName" [(ngModel)]="formCredentials.name"
            (keydown)="preventEnterKeyTigger($event)" required>
        </div>
        <!-- field end -->

        <!-- field start -->
        <div class="field">
          <label for="surname">{{'registration.form.fields.lastName' | translate}}:</label>
          <input type="text" name="surname" id="surname" [(ngModel)]="formCredentials.surname"
            (keydown)="preventEnterKeyTigger($event)" required>
        </div>
        <!-- field end -->

        <!-- field start -->
        <div class="field">
          <label for="mail">{{'registration.form.fields.email' | translate}}:</label>
          <input type="email" name="mail" id="mail" [(ngModel)]="formCredentials.mail"
            patterns="^[^\s@]+@[^\s@]+\.[^\s@]+$" (keydown)="preventEnterKeyTigger($event)" required>
        </div>
        <!-- field end -->

        <!-- field start -->
        <div class="field field-tips">
          <label for="securityData">{{'registration.form.fields.password' | translate}}:</label>

          <!-- input-with-tips-wrapper start -->
          <div class="input-wrapper">
            <span class="tips">Should contain min. 6 characters. At least one letter or number and<br /> special
              characters</span>
            <input type="password" name="securityData" id="securityData" [(ngModel)]="formCredentials.securityData"
              pattern="^(?=.*[a-zA-Z\d])(?=.*[!@#$%^&*.]).{6,}$" (keydown)="preventEnterKeyTigger($event)" required>
          </div>
        </div>
        <!-- input-wrapper end -->

        <!-- field end -->

        <!-- field start -->
        <div class="field">
          <label for="confirmPassword">{{'registration.form.fields.confirmPassword' | translate}}:</label>
          <input type="password" name="confirmPassword" id="confirmPassword" [(ngModel)]="confirmPassword"
            pattern="^(?=.*[a-zA-Z\d])(?=.*[!@#$%^&*.]).{6,}$" (keydown)="preventEnterKeyTigger($event)" required>
        </div>
        <!-- field end -->

        <!-- field start -->
        <div class="field field-phone">
          <label for="phone">{{'registration.form.fields.phone' | translate}}:</label>

          <!-- input-wrapper start -->
          <div class="input-wrapper">
            <select name="countryCode" id="countryCode" required [(ngModel)]="selectedCountryCode"
              (keydown)="preventEnterKeyTigger($event)">
              <option [ngValue]="null"></option>
              <option *ngFor="let item of countryCodes" [ngValue]="item">{{item.countryCode}} ({{item.phoneCode}})
              </option>
            </select>
            <input type="tel" name="phone" id="phone" [(ngModel)]="formCredentials.phone" placeholder="1234567890"
              (keydown)="preventEnterKeyTigger($event)" required>
          </div>
          <!-- input-wrapper end -->

        </div>
        <!-- field end -->

        <!-- invalid message start -->
        <div class="invalidMsg" *ngIf="allFieldsRequired">
          {{'registration.form.validationMsg.allFieldsRequired'|translate}}</div>
        <!-- invalid message end -->

        <!-- not equal passwords message start -->
        <div class="invalidMsg" *ngIf="passwordsNotEqual">
          {{'registration.form.validationMsg.passwordsNotEqual'|translate}}</div>
        <!-- not equal passwords message end -->

        <!-- not equal passwords message start -->
        <div class="invalidMsg" *ngIf="passwordNotValid">
          {{'registration.form.validationMsg.passwordNotValid1'|translate}} <br />
          {{'registration.form.validationMsg.passwordNotValid2'|translate}}</div>
        <!-- not equal passwords message end -->

        <!-- not equal passwords message start -->
        <div class="invalidMsg" *ngIf="emailNotValid">{{'registration.form.validationMsg.emailNotValid'|translate}}
        </div>
        <!-- not equal passwords message end -->

        <!-- invalid message start -->
        <div class="invalidMsg" *ngIf="apiErrorMessage">{{apiErrorMessage}}</div>
        <!-- invalid message end -->

        <!-- buttons block start -->
        <div class="buttons-block">
          <!-- button start -->
          <button class="btn btn-cancel" (click)="cancel($event);">{{'registration.form.buttons.cancel' |
            translate}}</button>
          <!-- button end -->
          <!-- button start -->
          <button type="submit" class="btn btn-register">{{'registration.form.buttons.register' | translate}}</button>
          <!-- button end -->
        </div>
        <!-- buttons block end -->
      </form>
      <!-- registration form end -->

      <!-- registration successful message start -->
      <div class="msg-after-reg" *ngIf="registrationSuccessful">We will review your registration data and send you an
        email when the account will be
        active</div>
      <!-- registration successful message end -->

    </div>
    <!-- main container end -->
  </div>
  <!-- registration section end -->

</ng-container>

<!-- registration component end -->
