import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

import { forkJoin } from 'rxjs';
import { ApiService, SnackBarService, StateService } from 'src/app/services';
import { IFluid, IFluidClass, ITranslation } from 'src/app/services/interfaces';
import { TParameter, IGas, TPhysicalUnits, TPhysicalUnitSystems, TFluidSpecialProps, TFluidProps } from '../models';

import { Delaunay } from 'd3-delaunay';


@Component({
  selector: 'app-gas',
  templateUrl: './gas.component.html',
  styleUrls: ['./gas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GasComponent implements OnInit, OnChanges {
  private physicalPropsList: string[] = ['temperature', 'mass_flow_rate', 'pressure', 'density', 'viscosity', 'thermal_conductivity', 'specific_heat_cap'];

  public unitSystems: { unitSystems: TPhysicalUnitSystems; units: TPhysicalUnits; } = null;

  /**
   * Default unit for each `physical property`
   */
  public defaultUnitSystems: { [key: string]: number } = {};

  public treeMenu: boolean = false;
  public treeControl = new NestedTreeControl<any>(node => node.children);
  public treeData = new MatTreeNestedDataSource<any>();

  public liquidSpecialData: TFluidProps[] = null;

  public parameters: IGas = {
    liquid: {
      name: null,
      selectable: null,
      fluidClassId: null,
      fluidDescId: null,
      fluidId: null,
      fluidNameId: null,
      isActive: null,
      isCustom: null
    },
    massFlowRate: {
      value: {
        userUnits: null,
        commonUnits: null
      },
      entered: false,
      calculated: false,
      physicalUnitId: null
    },
    inletPressure: {
      value: {
        userUnits: null,
        commonUnits: null
      },
      entered: false,
      calculated: false,
      physicalUnitId: null
    },
    inletTemp: {
      value: {
        userUnits: null,
        commonUnits: null
      },
      entered: false,
      calculated: false,
      physicalUnitId: null
    },
    outletTemp: {
      value: {
        userUnits: null,
        commonUnits: null
      },
      entered: false,
      calculated: false,
      physicalUnitId: null
    },
    maxPressureDrop: {
      value: {
        userUnits: null,
        commonUnits: null
      },
      entered: false,
      calculated: false,
      physicalUnitId: null
    },
    fluidProps: null
  };

  public liquid: any = {
    name: null,
    selectable: null,
    fluidClassId: null,
    fluidDescId: null,
    fluidId: null,
    fluidNameId: null,
    isActive: null,
    isCustom: null
  };

  public customFluidModal: boolean = false;

  public customFluid = {
    name: null,
    invalid: false,
    temperature: {
      inlet: {
        entered: null,
        calculated: null,
        physicalUnitId: null,
        invalid: false
      },
      outlet: {
        entered: null,
        calculated: null,
        physicalUnitId: null,
        invalid: false
      }
    },
    pressure: {
      inlet: {
        entered: null,
        calculated: null,
        physicalUnitId: null,
        invalid: false
      }
    },
    density: {
      inlet: {
        entered: null,
        calculated: null,
        physicalUnitId: null,
        invalid: false
      },
      outlet: {
        entered: null,
        calculated: null,
        physicalUnitId: null,
        invalid: false
      }
    },
    viscosity: {
      inlet: {
        entered: null,
        calculated: null,
        physicalUnitId: null,
        invalid: false
      },
      outlet: {
        entered: null,
        calculated: null,
        physicalUnitId: null,
        invalid: false
      }

    },
    thermal_conductivity: {
      inlet: {
        entered: null,
        calculated: null,
        physicalUnitId: null,
        invalid: false
      },
      outlet: {
        entered: null,
        calculated: null,
        physicalUnitId: null,
        invalid: false
      }

    },
    specific_heat_cap: {
      inlet: {
        entered: null,
        calculated: null,
        physicalUnitId: null,
        invalid: false
      },
      outlet: {
        entered: null,
        calculated: null,
        physicalUnitId: null,
        invalid: false
      }

    },
    fluid_group: {
      value: null,
      invalid: false
    }
  };
  public savedCustomFluid: IFluid = null;

  @Input()
  public side: 'warm' | 'cold' = null;
  @Input()
  public extraDuty: boolean = false;
  @Input()
  public liquidType: string = null;
  @Input()
  public existingParams: any = null;
  @Input()
  public otherSideParams: any = null;
  @Input()
  public heatExchangeRate: TParameter = null;
  @Input()
  public overdesign: number = null;
  @Input()
  public validation: { valid: boolean } = null;

  @Output()
  public parametersEntered: EventEmitter<IGas> = new EventEmitter();
  @Output()
  public parametersCalculated: EventEmitter<IGas> = new EventEmitter();

  constructor(private stateService: StateService, private apiService: ApiService, private snackBarService: SnackBarService, private changeDetection: ChangeDetectorRef) { };

  public ngOnInit(): void {
    const savedUserUnitSystem: string = this.stateService.get('user').unitSystem;

    this.apiService.getAllUnitSystemData(this.physicalPropsList).subscribe({
      next: (_unitSystems) => {
        this.unitSystems = _unitSystems;

        // Create default unit for each prop
        // 1. Find unit system saved in user profile
        // 2. Find unit system with unitSystemId 1
        // If user unit system present save it physicalUnitId, else save physicalUnitId from unit system with unitSystemId 1
        this.physicalPropsList.forEach((_propName: string) => {
          const userUnitSystem = _unitSystems.unitSystems[_propName].find((_x) => _x.unitSystemId === +savedUserUnitSystem);
          const firstUnitSystem = _unitSystems.unitSystems[_propName].find((_x) => _x.unitSystemId === 1);

          if (userUnitSystem) {
            this.defaultUnitSystems[_propName] = userUnitSystem.physicalUnitId;
          } else {
            this.defaultUnitSystems[_propName] = firstUnitSystem.physicalUnitId;
          };

        });
        // Save default unit system for each parameter.
        this.parameters.massFlowRate.physicalUnitId = this.defaultUnitSystems.mass_flow_rate;
        this.parameters.inletPressure.physicalUnitId = this.defaultUnitSystems.pressure;
        this.parameters.inletTemp.physicalUnitId = this.defaultUnitSystems.temperature;
        this.parameters.outletTemp.physicalUnitId = this.defaultUnitSystems.temperature;
        this.parameters.maxPressureDrop.physicalUnitId = this.defaultUnitSystems.pressure;

        this.setPressureDefaultValue();

        if (this.existingParams && !this.liquid.name) {
          const params: IGas = this.prepareParams(this.existingParams);
          this.parameters = params;

          this.vmGetTranslationOfSavedFluid(params.liquid);
        };

        this.changeDetection.markForCheck();

      },
      error: (error: HttpErrorResponse) => {
        this.snackBarService.open();
      }
    });
  };

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.liquidType?.currentValue && changes.liquidType?.firstChange) {
      this.getLiquidData();
    };

    if (changes?.heatExchangeRate) {
      this.validate();
    };

    if (changes.validation?.currentValue.valid) {
      this.calculateParameters();
    };

  };

  public switchCustomFluidModal(): void {

    this.customFluidModal = !this.customFluidModal;

    // If modal closed, reset data for custom fluid.
    if (!this.customFluidModal) {
      this.customFluid = {
        name: null,
        invalid: false,
        temperature: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          },
          outlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }
        },
        pressure: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }
        },
        density: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          },
          outlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }

        },
        viscosity: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          },
          outlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }

        },
        thermal_conductivity: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          },
          outlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }

        },
        specific_heat_cap: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          },
          outlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }

        },
        fluid_group: {
          value: null,
          invalid: false
        }
      };
      return;
    };

    // Save default unit systems for custom fluid parameter
    // Inlet
    this.customFluid.temperature.inlet.physicalUnitId = this.defaultUnitSystems.temperature;
    this.customFluid.pressure.inlet.physicalUnitId = this.defaultUnitSystems.pressure;
    this.customFluid.density.inlet.physicalUnitId = this.defaultUnitSystems.density;
    this.customFluid.viscosity.inlet.physicalUnitId = this.defaultUnitSystems.viscosity;
    this.customFluid.thermal_conductivity.inlet.physicalUnitId = this.defaultUnitSystems.thermal_conductivity;
    this.customFluid.specific_heat_cap.inlet.physicalUnitId = this.defaultUnitSystems.specific_heat_cap;

    // Outlet
    this.customFluid.temperature.outlet.physicalUnitId = this.defaultUnitSystems.temperature;
    this.customFluid.density.outlet.physicalUnitId = this.defaultUnitSystems.density;
    this.customFluid.viscosity.outlet.physicalUnitId = this.defaultUnitSystems.viscosity;
    this.customFluid.thermal_conductivity.outlet.physicalUnitId = this.defaultUnitSystems.thermal_conductivity;
    this.customFluid.specific_heat_cap.outlet.physicalUnitId = this.defaultUnitSystems.specific_heat_cap;

    // If temperature values from duty exist
    // use it as initial values for temperature for custom fluid
    if (this.parameters.inletTemp.value.commonUnits) {
      this.customFluid.temperature.inlet.calculated = this.parameters.inletTemp.value.commonUnits;
      this.customFluid.temperature.inlet.entered = Number(this.convertToUser(this.customFluid.temperature.inlet.calculated, 'temperature', this.customFluid.temperature.inlet.physicalUnitId).toFixed(1));
    };
    if (this.parameters.outletTemp.value.commonUnits) {
      this.customFluid.temperature.outlet.calculated = this.parameters.outletTemp.value.commonUnits;
      this.customFluid.temperature.outlet.entered = Number(this.convertToUser(this.customFluid.temperature.outlet.calculated, 'temperature', this.customFluid.temperature.outlet.physicalUnitId).toFixed(1));
    };

    // If pressure values from duty exist
    // use it as initial values for pressure for custom fluid
    if (this.parameters.inletPressure.value.commonUnits) {
      this.customFluid.pressure.inlet.calculated = this.parameters.inletPressure.value.commonUnits;
      this.customFluid.pressure.inlet.entered = Number(this.convertToUser(this.customFluid.pressure.inlet.calculated, 'pressure', this.customFluid.pressure.inlet.physicalUnitId).toFixed(1));
    };



  };

  public saveCustomFluid() {
    // Check that all fields have values
    Object.keys(this.customFluid).forEach((_key: string) => {
      if (_key === 'name' || _key === 'invalid') {
        this.customFluid.invalid = !!this.customFluid.name ? false : true;
      } else {
        if (_key === 'fluid_group') {
          this.customFluid.fluid_group.invalid = !!this.customFluid.fluid_group.value ? false : true;
        } else if (_key == 'pressure') {
          this.customFluid[_key].inlet.invalid = !!this.customFluid[_key].inlet.calculated || this.customFluid[_key].inlet.calculated === 0 ? false : true;
        } else if (_key !== 'pressure') {
          this.customFluid[_key].inlet.invalid = !!this.customFluid[_key].inlet.calculated || this.customFluid[_key].inlet.calculated === 0 ? false : true;
          this.customFluid[_key].outlet.invalid = !!this.customFluid[_key].outlet.calculated || this.customFluid[_key].outlet.calculated === 0 ? false : true;
        };
      };

    });

    // Get all validation values
    const validationFlags = [
      this.customFluid.invalid,
      this.customFluid.temperature.inlet.invalid,
      this.customFluid.pressure.inlet.invalid,
      this.customFluid.density.inlet.invalid,
      this.customFluid.viscosity.inlet.invalid,
      this.customFluid.thermal_conductivity.inlet.invalid,
      this.customFluid.specific_heat_cap.inlet.invalid,
      this.customFluid.temperature.outlet.invalid,
      this.customFluid.density.outlet.invalid,
      this.customFluid.viscosity.outlet.invalid,
      this.customFluid.thermal_conductivity.outlet.invalid,
      this.customFluid.specific_heat_cap.outlet.invalid,
      this.customFluid.fluid_group.invalid
    ];

    // If some fields not valid, show validation message
    if (validationFlags.some((_x) => _x === true)) {
      this.snackBarService.open('All fields must be filled in.');
      return;
    };

    const data = {
      customName: this.customFluid.name,
      ItemId: this.stateService.get('itemId'),
      usageModel: {
        usage: "GA",
        productType: "ST"
      },
      fluidProperties: []
    };

    Object.keys(this.customFluid).forEach((_key: string) => {
      if (_key !== 'name' && _key !== 'invalid' && _key !== 'temperature' && _key !== 'fluid_group' && _key !== 'pressure') {
        Object.keys(this.customFluid[_key]).forEach((_innerKey: string) => {
          data.fluidProperties.push({
            physicalPropertyId: _key,
            propertyValue: this.customFluid[_key][_innerKey]?.calculated,
            dependency1Value: this.customFluid.temperature[_innerKey]?.calculated
          });
        });
      } else if (_key === 'fluid_group') {
        data.fluidProperties.push({
          physicalPropertyId: _key,
          propertyValue: this.customFluid[_key].value,
          dependency1Value: this.customFluid.temperature.inlet?.calculated
        });
        data.fluidProperties.push({
          physicalPropertyId: _key,
          propertyValue: this.customFluid[_key].value,
          dependency1Value: this.customFluid.temperature.outlet?.calculated
        });
      };
    });

    this.apiService.saveCustomFluid(data).subscribe({
      next: (customFluidResp) => {

        this.savedCustomFluid = customFluidResp;

        this.getLiquidData();
      },
      error: (error) => {
        this.snackBarService.open('Can`t save this fluid.');
      }
    });

  };

  /** Method to store value and change validation status when parameter of custom fluid changed. */
  public onCustomFluidParameterChange(parameter, propName: string, value: number | string): void {

    // If property exist it main object with data
    // If not, its parameter
    if (parameter.hasOwnProperty('name')) {

      if (!!value) {
        this.customFluid.name = value;
        this.customFluid.invalid = false;
      } else {
        this.customFluid.name = null;
        this.customFluid.invalid = true;
      }

    } else {

      if (propName === 'fluid_group') {
        parameter.value = value;
        parameter.invalid = !!value ? false : true;
      } else {
        if (!!value || value === 0) {
          parameter.entered = value;
          parameter.calculated = this.convertToSI(parameter.entered, propName, parameter.physicalUnitId);
          parameter.invalid = false;
        } else {
          parameter.entered = null;
          parameter.calculated = null;
          parameter.invalid = true;
        };
      };

    };

  };
  /** Method to change units for parameters for custom fluid. */
  public onCustomFluidUnitChange(parameter, propName: string, physicalUnitId: number): void {

    parameter.physicalUnitId = physicalUnitId;

    if (parameter.calculated) {
      parameter.entered = Number(this.convertToUser(parameter.calculated, propName, parameter.physicalUnitId).toFixed(1));
    };

  };

  /** Methos for tree menu */
  public hasChild(_: number, node: any) {
    return !!node.children && node.children.length > 0
  };

  /** Method to select liquid by click on tree item. And get fluid physical props.*/
  public onLiquidSelect(node: any): void {

    if (node.selectable) {
      this.liquid = node;
      this.parameters.liquid = this.liquid;
      this.treeMenu = false;
      let property_name = (node.isCustom === 1) ? 'specific_heat_cap' : 'specific_heat_cap_tp';

      this.apiService.getFluidPhysicalProperty(node.fluidId, property_name).subscribe({
        next: (resp) => {
          this.liquidSpecialData = resp;
          this.parameters.fluidProps = {
            specific_heat_cap: resp
          };
          this.treeControl.collapseAll();

          this.emitParametersEnteredEvent();
        },
        error: (error: HttpErrorResponse) => {
          this.snackBarService.open();
        }
      });

    };

  };

  /** Method store value and change parameter status on input change */
  public onParameterChange(paramName: string, value: number): void {
    if (!!value || value === 0) {
      this.parameters[paramName].value.userUnits = value;
      this.parameters[paramName].entered = true;
      this.parameters[paramName].calculated = false;
    } else {
      this.parameters[paramName].value.userUnits = null;
      this.parameters[paramName].entered = false;
      this.parameters[paramName].calculated = false;
    };

    // Convert params to common units
    this.parameters.massFlowRate.value.commonUnits = this.convertToSI(this.parameters.massFlowRate.value.userUnits, 'mass_flow_rate', this.parameters.massFlowRate.physicalUnitId);
    this.parameters.inletTemp.value.commonUnits = this.convertToSI(this.parameters.inletTemp.value.userUnits, 'temperature', this.parameters.inletTemp.physicalUnitId);
    this.parameters.outletTemp.value.commonUnits = this.convertToSI(this.parameters.outletTemp.value.userUnits, 'temperature', this.parameters.outletTemp.physicalUnitId);
    this.parameters.inletPressure.value.commonUnits = this.convertToSI(this.parameters.inletPressure.value.userUnits, 'pressure', this.parameters.inletPressure.physicalUnitId);
    this.parameters.maxPressureDrop.value.commonUnits = this.convertToSI(this.parameters.maxPressureDrop.value.userUnits, 'pressure', this.parameters.maxPressureDrop.physicalUnitId);

    this.emitParametersEnteredEvent();
    this.validate();

  };
  public onUnitChange(paramName: string, propName: string, physicalUnitId: number): void {

    this.parameters[paramName].physicalUnitId = physicalUnitId;

    if (this.parameters[paramName].value.commonUnits) {
      this.parameters[paramName].value.userUnits = Number(this.convertToUser(this.parameters[paramName].value.commonUnits, propName, this.parameters[paramName].physicalUnitId).toFixed(1));
    };

  }
  /**
   * Method to convert value `from users unit system to SI`.
   * @param value
   * @param propName physical property name. See `physicalPropsList property` for correct typing
   * @returns
   */
  public convertToSI(value: number, propName: string, physicalUnitId: number): number {
    const prop = this.unitSystems.units[propName].find((x) => x.physicalUnitId === physicalUnitId);
    if (value === 0 || !value) {
      return value;
    };

    return prop.addFactor + (value * prop.multFactor)

  };
  /**
   * Method to convert value `from SI unit system to users unit system`.
   * @param value
   * @param propName physical property name. See `physicalPropsList property` for correct typing
   * @returns
   */
  public convertToUser(value: number, propName: string, physicalUnitId: number): number {
    const prop = this.unitSystems.units[propName].find((x) => x.physicalUnitId === physicalUnitId);
    if (value === 0 || !value) {
      return value;
    };

    return (value - prop.addFactor) / prop.multFactor;

  };

  private validate() {
    const enteredParams = [this.parameters.massFlowRate.entered, this.parameters.inletTemp.entered, this.parameters.outletTemp.entered, this.parameters.inletPressure.entered].filter((x) => !!x).length;

    if (this.extraDuty) {
      if (this.validation.valid) {
        this.parametersCalculated.emit(this.parameters);
      };
    } else {
      if (enteredParams === 3) {
        this.calculateParameters();
      };
    }
  };

  /**
   * Method to emit event that parameters changed.
   */
  private emitParametersEnteredEvent() {
    this.parametersEntered.emit(this.parameters);
  };

  private getLiquidData() {
    // Get fluids, by parameters from step 2
    forkJoin({
      fluidClasses: this.apiService.getFluidClasses(),
      fluids: this.apiService.getFluids(this.liquidType, 'ST', this.stateService.get('itemId')),
    }).subscribe({
      next: ({ fluidClasses, fluids }) => {
        let fluidClassesIds = [];
        let fluidsIds = [];

        // Get IDs for each item, to get translations.
        fluidClasses.forEach((_x) => {
          fluidClassesIds.push(_x.classNameId);
        });

        fluids.forEach((_x) => {
          fluidsIds.push(_x.fluidNameId);
          if (_x.fluidDescId)
            fluidsIds.push(_x.fluidDescId);
        });

        // Filter valid Ids
        fluidClassesIds = fluidClassesIds.filter((x) => !!x);
        fluidsIds = fluidsIds.filter((x) => !!x);

        // Get translations
        forkJoin({
          fluidClassesCurrentLangTranslation: this.apiService.getTranslation(fluidClassesIds, this.stateService.get('lang').toUpperCase()),
          fluidsCurrentLangTranslation: this.apiService.getTranslation(fluidsIds, this.stateService.get('lang').toUpperCase()),
          fluidClassesDefaultLangTranslation: this.apiService.getTranslation(fluidClassesIds, 'EN'),
          fluidsDefaultLangTranslation: this.apiService.getTranslation(fluidsIds, 'EN'),
        }).subscribe({
          next: (translatioNResponse) => {

            const translations = {
              current: [...translatioNResponse.fluidClassesCurrentLangTranslation, ...translatioNResponse.fluidsCurrentLangTranslation],
              default: [...translatioNResponse.fluidClassesDefaultLangTranslation, ...translatioNResponse.fluidsDefaultLangTranslation],
            };

            const { nodes, defaultFluid } = this.createTree(fluidClasses, fluids, translations);

            this.treeData.data = nodes;

            // If no saved application found or if saved custom fluid exist
            // select default fluid.
            if (!this.existingParams || !!this.savedCustomFluid) {
              this.onLiquidSelect(defaultFluid);
            };

            // If custom fluid modal opened, its mean that user created its own fluid and saved it.
            // Need to close this modal window.
            if (this.customFluidModal) {

              if (!this.parameters.inletTemp.value.commonUnits && this.parameters.inletTemp.value.commonUnits !== 0) {

                this.parameters.inletTemp.value.commonUnits = this.customFluid.temperature.inlet.calculated;
                this.parameters.inletTemp.value.userUnits = this.convertToUser(this.parameters.inletTemp.value.commonUnits, 'temperature', this.parameters.inletTemp.physicalUnitId);

                this.parameters.inletTemp.entered = true;
                this.parameters.inletTemp.calculated = false;
              };

              if (!this.parameters.outletTemp.value.commonUnits && this.parameters.outletTemp.value.commonUnits !== 0) {

                this.parameters.outletTemp.value.commonUnits = this.customFluid.temperature.outlet.calculated;
                this.parameters.outletTemp.value.userUnits = this.convertToUser(this.parameters.outletTemp.value.commonUnits, 'temperature', this.parameters.outletTemp.physicalUnitId);

                this.parameters.outletTemp.entered = true;
                this.parameters.outletTemp.calculated = false;
              };

              if (!this.parameters.inletPressure.value.commonUnits && this.parameters.inletPressure.value.commonUnits !== 0) {

                this.parameters.inletPressure.value.commonUnits = this.customFluid.pressure.inlet.calculated;
                this.parameters.inletPressure.value.userUnits = this.convertToUser(this.parameters.inletPressure.value.commonUnits, 'pressure', this.parameters.inletPressure.physicalUnitId);

                this.parameters.inletPressure.entered = true;
                this.parameters.inletPressure.calculated = false;
              };


              this.switchCustomFluidModal();
            };
          },
          error: (error: HttpErrorResponse) => {
            this.snackBarService.open()
          }
        });


      },
      error: (error: HttpErrorResponse) => {
        this.snackBarService.open();
      }
    })
  };

  /** Method to create tree data for menu */
  private createTree(fluidClasses: IFluidClass[], fluids: IFluid[], translations: { current: ITranslation[]; default: ITranslation[] }) {
    /** Default fluid. Can be `water or custom fluid created by user`. */
    let defaultFluid = null;

    const savedCustomFluidTmp = this.savedCustomFluid;

    /** Node of custom fluid created by user */
    let customFluidNode = null;

    // Find main categories.
    const mainCategories: IFluidClass[] = fluidClasses.filter((_x) => !_x.parentClassId && _x.isActive === 1);

    // All nodes
    const nodes = [];

    // Create node for each category
    mainCategories.forEach((_mainCategory: IFluidClass) => {
      const mainCategoryNode = {
        name: null,
        selectable: false,
        children: null
      };

      // Find translation on users language and in English as default language.
      const userLang = translations.current.find((_translation) => _translation.translationId === _mainCategory.classNameId)?.value;
      const defaultLang = translations.default.find((_translation) => _translation.translationId === _mainCategory.classNameId)?.value;

      mainCategoryNode.name = userLang || defaultLang;

      // Find all children for current category
      mainCategoryNode.children = findChildren(_mainCategory);

      // If no children found, dont save this node.
      if (mainCategoryNode.children && mainCategoryNode.children.length > 0) {
        nodes.push(Object.assign(mainCategoryNode, _mainCategory));
      };


    });

    /** Function to recirsivly find children */
    function findChildren(parentFluidClass: IFluidClass) {

      // 1. Find sub categories for parent category
      // 2. Find fluids for parent category
      const subCategories = fluidClasses.filter((_fluidClass) => _fluidClass.parentClassId === parentFluidClass.fluidClassId && _fluidClass.isActive === 1);
      const parentFluids = fluids.filter((_fluid) => _fluid.fluidClassId === parentFluidClass.fluidClassId && _fluid.isActive === 1);

      // If fluids and sub categories not found, return null
      if (parentFluids.length === 0 && subCategories.length === 0) {
        return null;
      };

      const childNodes = [];

      // If sub category found
      // Create node for each category and find its children
      if (subCategories.length > 0) {

        subCategories.forEach((_subCategory) => {

          const _node = {
            name: null,
            selectable: false,
            children: null
          };

          // Find translation on users language and in English as default language.
          const userLang = translations.current.find((_translation) => _translation.translationId === _subCategory.classNameId)?.value;
          const defaultLang = translations.default.find((_translation) => _translation.translationId === _subCategory.classNameId)?.value;

          _node.name = userLang || defaultLang;

          _node.children = findChildren(_subCategory);

          // If node dont have children, dont save it.
          if (_node.children && _node.children.length > 0) {
            childNodes.push(Object.assign(_node, _subCategory));
          };
        });

      };

      // If fluids found
      // Create node for each fluid
      if (parentFluids.length > 0) {

        parentFluids.forEach((_parentFluid) => {
          const _node = {
            name: null,
            selectable: true,
          };
          // Find translation on users language and in English as default language.
          const userLang = translations.current.find((_translation) => _translation.translationId === _parentFluid.fluidNameId)?.value;
          const defaultLang = translations.default.find((_translation) => _translation.translationId === _parentFluid.fluidNameId)?.value;

          _node.name = userLang || defaultLang || _parentFluid.customName;

          childNodes.push(Object.assign(_node, _parentFluid));
        });

      };

      // Air is default fluid for Gas Application, find its node
      // If we previously saved custom fluid, we must find it node
      // If we have custom fluid node save it as default, otherwise Air will be default fluid.
      if (childNodes.length > 0) {
        let defaultFluidTmp = childNodes.find((_childNode) => _childNode?.fluidId === 1000);

        if (defaultFluidTmp) {
          defaultFluid = defaultFluidTmp;
        };

        if (savedCustomFluidTmp && !customFluidNode) {
          customFluidNode = childNodes.find((_childNode) => _childNode?.fluidId === savedCustomFluidTmp?.fluidId);
        };
      };

      //sort fluids tree
      childNodes.sort((a, b) => a.name.localeCompare(b.name));

      return childNodes;
    };

    // If no saved application found
    // select default fluid.
    if (!this.existingParams) {
      this.onLiquidSelect(defaultFluid);
    }

    //sort fluids tree
    nodes.sort((a, b) => a.name.localeCompare(b.name));
    defaultFluid = customFluidNode || defaultFluid;

    return { nodes, defaultFluid };
  };

  private calculateParameters() {

    let massFlowRate: number = this.parameters.massFlowRate.value.commonUnits;
    let inletTemp: number = this.parameters.inletTemp.value.commonUnits;
    let outletTemp: number = this.parameters.outletTemp.value.commonUnits;

    if (this.side === 'warm' && this.heatExchangeRate.value.commonUnits && !this.extraDuty) {
      if (!this.parameters.inletTemp.entered && (!!outletTemp || outletTemp === 0) && (!!massFlowRate || massFlowRate === 0)) {

        const outletTempSHC = this.findSpecificHeatCapacity(outletTemp, this.liquidSpecialData);
        let inletTempSHC = null;

        let avarageSHC = null;
        let rangeOfSHC = null;

        let temperatures = null;

        // 1
        inletTemp = this.calculateWarmInletTemp(outletTemp, outletTempSHC, massFlowRate, this.heatExchangeRate.value.commonUnits);

        // 2
        inletTempSHC = this.findSpecificHeatCapacity(inletTemp, this.liquidSpecialData);

        temperatures = [inletTemp, outletTemp].sort((a, b) => a - b);

        rangeOfSHC = this.liquidSpecialData.filter((_item) => _item.dependency1Value > temperatures[0] && _item.dependency1Value < temperatures[1]).map((_item) => _item.propertyValue);
        rangeOfSHC = [...rangeOfSHC, inletTempSHC, outletTempSHC];

        avarageSHC = rangeOfSHC.reduce((acc, next) => acc + next, 0) / rangeOfSHC.length;

        inletTemp = this.calculateWarmInletTemp(outletTemp, avarageSHC, massFlowRate, this.heatExchangeRate.value.commonUnits);
        // 3
        inletTempSHC = this.findSpecificHeatCapacity(inletTemp, this.liquidSpecialData);

        temperatures = [inletTemp, outletTemp].sort((a, b) => a - b);

        rangeOfSHC = this.liquidSpecialData.filter((_item) => _item.dependency1Value > temperatures[0] && _item.dependency1Value < temperatures[1]).map((_item) => _item.propertyValue);
        rangeOfSHC = [...rangeOfSHC, inletTempSHC, outletTempSHC];

        avarageSHC = rangeOfSHC.reduce((acc, next) => acc + next, 0) / rangeOfSHC.length;

        inletTemp = this.calculateWarmInletTemp(outletTemp, avarageSHC, massFlowRate, this.heatExchangeRate.value.commonUnits);

        this.parameters.inletTemp.entered = false;
        this.parameters.inletTemp.calculated = true;

        this.parameters.inletTemp.value.commonUnits = inletTemp;
        this.parameters.inletTemp.value.userUnits = this.convertToUser(inletTemp, 'temperature', this.parameters.inletTemp.physicalUnitId);
        this.parameters.inletTemp.value.userUnits = Number(this.parameters.inletTemp.value.userUnits.toFixed(1));

      };
      if (!this.parameters.outletTemp.entered && (!!inletTemp || inletTemp === 0) && (!!massFlowRate || massFlowRate === 0)) {

        // get SHC value for entered Inlet Temperatuer
        const inletTempSHC = this.findSpecificHeatCapacity(inletTemp, this.liquidSpecialData);
        let outletTempSHC = null;

        let temperatures = null;
        let rangeOfSHC = null;
        let avarageSHC = null;

        // 1
        outletTemp = this.calculateWarmOutletTemp(inletTemp, inletTempSHC, massFlowRate, this.heatExchangeRate.value.commonUnits);

        // 2
        outletTempSHC = this.findSpecificHeatCapacity(outletTemp, this.liquidSpecialData);

        temperatures = [inletTemp, outletTemp].sort((a, b) => a - b);

        rangeOfSHC = this.liquidSpecialData.filter((_item) => _item.dependency1Value > temperatures[0] && _item.dependency1Value < temperatures[1]).map((_item) => _item.propertyValue);
        rangeOfSHC = [...rangeOfSHC, inletTempSHC, outletTempSHC];
        avarageSHC = rangeOfSHC.reduce((acc, next) => acc + next, 0) / rangeOfSHC.length;

        outletTemp = this.calculateWarmOutletTemp(inletTemp, avarageSHC, massFlowRate, this.heatExchangeRate.value.commonUnits);

        // 3
        outletTempSHC = this.findSpecificHeatCapacity(outletTemp, this.liquidSpecialData);

        temperatures = [inletTemp, outletTemp].sort((a, b) => a - b);

        rangeOfSHC = this.liquidSpecialData.filter((_item) => _item.dependency1Value > temperatures[0] && _item.dependency1Value < temperatures[1]).map((_item) => _item.propertyValue);
        rangeOfSHC = [...rangeOfSHC, inletTempSHC, outletTempSHC];
        avarageSHC = rangeOfSHC.reduce((acc, next) => acc + next, 0) / rangeOfSHC.length;

        outletTemp = this.calculateWarmOutletTemp(inletTemp, avarageSHC, massFlowRate, this.heatExchangeRate.value.commonUnits);

        this.parameters.outletTemp.entered = false;
        this.parameters.outletTemp.calculated = true;

        this.parameters.outletTemp.value.commonUnits = outletTemp;
        this.parameters.outletTemp.value.userUnits = this.convertToUser(outletTemp, 'temperature', this.parameters.outletTemp.physicalUnitId);
        this.parameters.outletTemp.value.userUnits = Number(this.parameters.outletTemp.value.userUnits.toFixed(1));
      };
      if (!this.parameters.massFlowRate.entered && (!!outletTemp || outletTemp === 0) && (!!inletTemp || inletTemp === 0)) {

        // Specific heat capacity value for inlet and outlet temperatures
        const inletTempSHC = this.findSpecificHeatCapacity(inletTemp, this.liquidSpecialData);
        const outletTempSHC = this.findSpecificHeatCapacity(outletTemp, this.liquidSpecialData);

        const temperatures = [outletTemp, inletTemp].sort((a, b) => a - b);

        let rangeOfSHC = this.liquidSpecialData.filter((_item) => _item.dependency1Value > temperatures[0] && _item.dependency1Value < temperatures[1]).map((_item) => _item.propertyValue);
        rangeOfSHC = [...rangeOfSHC, inletTempSHC, outletTempSHC];

        const avarageSHC = rangeOfSHC.reduce((acc, next) => acc + next, 0) / rangeOfSHC.length;

        massFlowRate = this.calculateWarmMassFlowRate(outletTemp, inletTemp, avarageSHC, this.heatExchangeRate.value.commonUnits);

        this.parameters.massFlowRate.entered = false;
        this.parameters.massFlowRate.calculated = true;

        this.parameters.massFlowRate.value.commonUnits = massFlowRate;
        this.parameters.massFlowRate.value.userUnits = this.convertToUser(massFlowRate, 'mass_flow_rate', this.parameters.massFlowRate.physicalUnitId);
        this.parameters.massFlowRate.value.userUnits = Number(this.parameters.massFlowRate.value.userUnits.toFixed(1));
      };
    };

    if (this.side === 'cold' && this.heatExchangeRate.value.commonUnits && !this.extraDuty) {
      if (!this.parameters.inletTemp.entered && (!!outletTemp || outletTemp === 0) && (!!massFlowRate || massFlowRate === 0)) {


        const outletTempSHC = this.findSpecificHeatCapacity(outletTemp, this.liquidSpecialData);
        let inletTempSHC = null;

        let avarageSHC = null;
        let rangeOfSHC = null;

        let temperatures = null;

        // 1
        inletTemp = this.calculateColdInletTemp(outletTemp, outletTempSHC, massFlowRate, this.heatExchangeRate.value.commonUnits);

        // 2
        inletTempSHC = this.findSpecificHeatCapacity(inletTemp, this.liquidSpecialData);

        temperatures = [inletTemp, outletTemp].sort((a, b) => a - b);

        rangeOfSHC = this.liquidSpecialData.filter((_item) => _item.dependency1Value > temperatures[0] && _item.dependency1Value < temperatures[1]).map((_item) => _item.propertyValue);
        rangeOfSHC = [...rangeOfSHC, inletTempSHC, outletTempSHC];

        avarageSHC = rangeOfSHC.reduce((acc, next) => acc + next, 0) / rangeOfSHC.length;

        inletTemp = this.calculateColdInletTemp(outletTemp, avarageSHC, massFlowRate, this.heatExchangeRate.value.commonUnits);

        // 3
        inletTempSHC = this.findSpecificHeatCapacity(inletTemp, this.liquidSpecialData);
        temperatures = [inletTemp, inletTemp].sort((a, b) => a - b);

        rangeOfSHC = this.liquidSpecialData.filter((_item) => _item.dependency1Value > temperatures[0] && _item.dependency1Value < temperatures[1]).map((_item) => _item.propertyValue);
        rangeOfSHC = [...rangeOfSHC, inletTempSHC, outletTempSHC];

        avarageSHC = rangeOfSHC.reduce((acc, next) => acc + next, 0) / rangeOfSHC.length;

        inletTemp = this.calculateColdInletTemp(outletTemp, avarageSHC, massFlowRate, this.heatExchangeRate.value.commonUnits);

        this.parameters.inletTemp.entered = false;
        this.parameters.inletTemp.calculated = true;

        this.parameters.inletTemp.value.commonUnits = inletTemp;
        this.parameters.inletTemp.value.userUnits = this.convertToUser(inletTemp, 'temperature', this.parameters.inletTemp.physicalUnitId);
        this.parameters.inletTemp.value.userUnits = Number(this.parameters.inletTemp.value.userUnits.toFixed(1));
      };
      if (!this.parameters.outletTemp.entered && (!!inletTemp || inletTemp === 0) && (!!massFlowRate || massFlowRate === 0)) {

        const inletTempSHC = this.findSpecificHeatCapacity(inletTemp, this.liquidSpecialData);

        let outletTempSHC = null;

        let avarageSHC = null;
        let rangeOfSHC = null;

        let temperatures = null;
        // 1
        outletTemp = this.calculateColdOutletTemp(inletTemp, inletTempSHC, massFlowRate, this.heatExchangeRate.value.commonUnits);

        // 2
        outletTempSHC = this.findSpecificHeatCapacity(outletTemp, this.liquidSpecialData);

        temperatures = [outletTemp, inletTemp].sort((a, b) => a - b);

        rangeOfSHC = this.liquidSpecialData.filter((_item) => _item.dependency1Value > temperatures[0] && _item.dependency1Value < temperatures[1]).map((_item) => _item.propertyValue);
        rangeOfSHC = [...rangeOfSHC, inletTempSHC, outletTempSHC];

        avarageSHC = rangeOfSHC.reduce((acc, next) => acc + next, 0) / rangeOfSHC.length;

        outletTemp = this.calculateColdOutletTemp(inletTemp, avarageSHC, massFlowRate, this.heatExchangeRate.value.commonUnits);

        // 3
        outletTempSHC = this.findSpecificHeatCapacity(outletTemp, this.liquidSpecialData);
        temperatures = [outletTemp, inletTemp].sort((a, b) => a - b);

        rangeOfSHC = this.liquidSpecialData.filter((_item) => _item.dependency1Value > temperatures[0] && _item.dependency1Value < temperatures[1]).map((_item) => _item.propertyValue);
        rangeOfSHC = [...rangeOfSHC, inletTempSHC, outletTempSHC];

        avarageSHC = rangeOfSHC.reduce((acc, next) => acc + next, 0) / rangeOfSHC.length;

        outletTemp = this.calculateColdOutletTemp(inletTemp, avarageSHC, massFlowRate, this.heatExchangeRate.value.commonUnits);

        this.parameters.outletTemp.entered = false;
        this.parameters.outletTemp.calculated = true;

        this.parameters.outletTemp.value.commonUnits = outletTemp;
        this.parameters.outletTemp.value.userUnits = this.convertToUser(outletTemp, 'temperature', this.parameters.outletTemp.physicalUnitId);
        this.parameters.outletTemp.value.userUnits = Number(this.parameters.outletTemp.value.userUnits.toFixed(1));
      };
      if (!this.parameters.massFlowRate.entered && (!!outletTemp || outletTemp === 0) && (!!inletTemp || inletTemp === 0)) {
        // Specific heat capacity value for inlet and outlet temperatures
        const inletTempSHC = this.findSpecificHeatCapacity(inletTemp, this.liquidSpecialData);
        const outletTempSHC = this.findSpecificHeatCapacity(outletTemp, this.liquidSpecialData);

        const temperatures = [outletTemp, inletTemp].sort((a, b) => a - b);

        let rangeOfSHC = this.liquidSpecialData.filter((_item) => _item.dependency1Value > temperatures[0] && _item.dependency1Value < temperatures[1]).map((_item) => _item.propertyValue);
        rangeOfSHC = [...rangeOfSHC, inletTempSHC, outletTempSHC];
        let avarageSHC = rangeOfSHC.reduce((acc, next) => acc + next, 0) / rangeOfSHC.length;

        massFlowRate = this.calculateColdMassFlowRate(outletTemp, inletTemp, avarageSHC, this.heatExchangeRate.value.commonUnits);

        this.parameters.massFlowRate.entered = false;
        this.parameters.massFlowRate.calculated = true;

        this.parameters.massFlowRate.value.commonUnits = massFlowRate;
        this.parameters.massFlowRate.value.userUnits = this.convertToUser(massFlowRate, 'mass_flow_rate', this.parameters.massFlowRate.physicalUnitId);
        this.parameters.massFlowRate.value.userUnits = Number(this.parameters.massFlowRate.value.userUnits.toFixed(1));

      };
    };

    if (this.validation.valid) {
      this.parametersCalculated.emit(this.parameters);
    };

  };

  private calculateWarmOutletTemp(inletTemp: number, tempSHC: number, massFlowRate: number, heatExchangeRate: number): number {
    return inletTemp - (heatExchangeRate / (massFlowRate * tempSHC));
  };
  private calculateWarmInletTemp(outletTemp: number, tempSHC: number, massFlowRate: number, heatExchangeRate: number): number {
    return (heatExchangeRate / (massFlowRate * tempSHC)) + outletTemp;
  };
  private calculateWarmMassFlowRate(outletTemp: number, inletTemp: number, avarageSHC: number, heatExchangeRate: number): number {
    return heatExchangeRate / (avarageSHC * (inletTemp - outletTemp))
  };

  private calculateColdOutletTemp(inletTemp: number, tempSHC: number, massFlowRate: number, heatExchangeRate: number): number {
    return (heatExchangeRate / (massFlowRate * tempSHC)) + inletTemp;
  };
  private calculateColdInletTemp(outletTemp: number, tempSHC: number, massFlowRate: number, heatExchangeRate: number): number {
    return outletTemp - (heatExchangeRate / (massFlowRate * tempSHC));
  };
  private calculateColdMassFlowRate(outletTemp: number, inletTemp: number, avarageSHC: number, heatExchangeRate: number): number {
    return heatExchangeRate / (avarageSHC * (outletTemp - inletTemp));
  };

  /** Method to find `Specific Heat Capacity` for liquid */
  private findSpecificHeatCapacity(temperature, tableProps): number {
    const points = [];
    const values = [];

    //check if tableProps first point has empty dependency2Value then it is custom fluid data without pressure data
    if (tableProps[0].dependency2Value === null) {
      // List of all temperatures in table
      const tableTemperatures = tableProps.map((_x) => _x.dependency1Value);
      // Table min temperature
      const tableMinTemp = Math.min.apply(Math, tableTemperatures);
      // Table max temperature
      const tableMaxTemp = Math.max.apply(Math, tableTemperatures);


      let temperatureTableRecord = tableProps.find((_x) => _x.dependency1Value === temperature);
      let specificHeatCapacity = null;

      // 1. If record exist, take value.
      // 2. If record was no found in step 1, check if entered value is in RANGE of table temperatures.
      //    If true, find its value with linear interpolation.
      // 3. If value was not found in previous steps, check if entered value is out of range of table temperatures.
      //    If true, find closest temperature and take its value.

      if (!!temperatureTableRecord) {
        specificHeatCapacity = temperatureTableRecord.propertyValue;
      } else if (temperature >= tableMinTemp && temperature <= tableMaxTemp) {
        // Find closest temperatures for entered value
        const closestMinTemp = this.findClosestNumberSmaller(temperature, tableTemperatures);
        const closestMaxTemp = this.findClosestNumberBigger(temperature, tableTemperatures);

        const closestMinTempSHC = tableProps.find((_x) => _x.dependency1Value === closestMinTemp).propertyValue;
        const closestMaxTempSHC = tableProps.find((_x) => _x.dependency1Value === closestMaxTemp).propertyValue;

        specificHeatCapacity = closestMinTempSHC + (temperature - closestMinTemp) * (closestMaxTempSHC - closestMinTempSHC) / (closestMaxTemp - closestMinTemp);
      } else if (temperature > tableMaxTemp || temperature < tableMinTemp) {
        const closestTemp = this.findClosestNumber(temperature, tableTemperatures);
        specificHeatCapacity = tableProps.find((_x) => _x.dependency1Value === closestTemp).propertyValue;
      }
      return specificHeatCapacity;
    }

    tableProps.forEach((_prop) => {
      // dependency1Value - temperature
      // dependency2Value - pressure
      points.push([_prop.dependency2Value, _prop.dependency1Value]);
      values.push(_prop.propertyValue);
      //heck if the (pressure, temperature) point is already a known point
      if (_prop.dependency1Value === temperature && _prop.dependency2Value == this.parameters.inletPressure.value.commonUnits) {
        return _prop.propertyValue;
      }
    });

    // Use Delaunay triangulation to interpolate
    const delaunay = Delaunay.from(points);

    // Retrieve the surrounding triangle from the triangulation
    const triangles = delaunay.triangles;

    // Iterate through the triangles that use the nearest point and find the one containing the (pressure, temp)
    for (let i = 0; i < triangles.length; i += 3) {
      const p0Index = triangles[i];
      const p1Index = triangles[i + 1];
      const p2Index = triangles[i + 2];

      const p0 = points[p0Index];
      const p1 = points[p1Index];
      const p2 = points[p2Index];

      // Check if the (pressure, temperature) point is inside this triangle
      if (this.isPointInTriangle(this.parameters.inletPressure.value.commonUnits, temperature, p0, p1, p2)) {
        const v0 = values[p0Index];
        const v1 = values[p1Index];
        const v2 = values[p2Index];

        // Perform barycentric interpolation within the triangle
        const barycentric = this.getBarycentricCoordinates(this.parameters.inletPressure.value.commonUnits, temperature, p0, p1, p2);
        return barycentric[0] * v0 + barycentric[1] * v1 + barycentric[2] * v2;
      }
    }

    // Fallback: if no triangle contains the point, return the value at the nearest point (basic nearest neighbor)
    // Find the triangle containing the point (inletPressure, temperature)
    const nearestPointIndex = delaunay.find(this.parameters.inletPressure.value.commonUnits, temperature);

    return values[nearestPointIndex];
  };

  private findClosestNumberSmaller(x: number, arr: number[]): number | null {
    const filteredArr = arr.filter((k) => k < x);
    const indexArr = filteredArr.map((k) => Math.abs(k - x));
    const min = Math.min(...indexArr);
    return filteredArr[indexArr.indexOf(min)];
  }

  private findClosestNumberBigger(x: number, arr: number[]): number | null {
    const filteredArr = arr.filter((k) => k > x);
    const indexArr = filteredArr.map((k) => Math.abs(k - x));
    const min = Math.min(...indexArr);
    return filteredArr[indexArr.indexOf(min)];
  }

  private findClosestNumber(x, arr) {
    const indexArr = arr.map((k) => {
      return Math.abs(k - x)
    })
    const min = Math.min.apply(Math, indexArr)
    return arr[indexArr.indexOf(min)]
  };

  // Helper function to check if a point (x, y) is inside a triangle defined by (p0, p1, p2)
  private isPointInTriangle(x: number, y: number, p0: number[], p1: number[], p2: number[]): boolean {
    const [x0, y0] = p0;
    const [x1, y1] = p1;
    const [x2, y2] = p2;

    const dX = x - x2;
    const dY = y - y2;
    const dX21 = x2 - x1;
    const dY12 = y1 - y2;
    const D = dY12 * (x0 - x2) + dX21 * (y0 - y2);
    const s = dY12 * dX + dX21 * dY;
    const t = (y2 - y0) * dX + (x0 - x2) * dY;

    if (D < 0) return s <= 0 && t <= 0 && s + t >= D;
    return s >= 0 && t >= 0 && s + t <= D;
  }

  // Helper function to calculate barycentric coordinates
  private getBarycentricCoordinates(x: number, y: number, p0: number[], p1: number[], p2: number[]): number[] {
    const detT = (p1[1] - p2[1]) * (p0[0] - p2[0]) + (p2[0] - p1[0]) * (p0[1] - p2[1]);
    const l1 = ((p1[1] - p2[1]) * (x - p2[0]) + (p2[0] - p1[0]) * (y - p2[1])) / detT;
    const l2 = ((p2[1] - p0[1]) * (x - p2[0]) + (p0[0] - p2[0]) * (y - p2[1])) / detT;
    const l3 = 1 - l1 - l2;
    return [l1, l2, l3];
  }

  private prepareParams(params: any) {
    const result: IGas = {
      fluidProps: null,
      liquid: Object.assign({}, params.liquid),
      massFlowRate: {
        value: {
          userUnits: this.convertToUser(params.massFlowRate.value, 'mass_flow_rate', this.parameters.massFlowRate.physicalUnitId),
          commonUnits: params.massFlowRate.value
        },
        entered: params.massFlowRate.entered,
        calculated: params.massFlowRate.calculated,
        physicalUnitId: this.parameters.massFlowRate.physicalUnitId
      },
      inletPressure: {
        value: {
          userUnits: this.convertToUser(params.inletPressure.value, 'pressure', this.parameters.inletPressure.physicalUnitId),
          commonUnits: params.inletPressure.value
        },
        entered: params.inletPressure.entered,
        calculated: params.inletPressure.calculated,
        physicalUnitId: this.parameters.inletPressure.physicalUnitId
      },
      inletTemp: {
        value: {
          userUnits: this.convertToUser(params.inletTemp.value, 'temperature', this.parameters.inletTemp.physicalUnitId),
          commonUnits: params.inletTemp.value
        },
        entered: params.inletTemp.entered,
        calculated: params.inletTemp.calculated,
        physicalUnitId: this.parameters.inletTemp.physicalUnitId
      },
      outletTemp: {
        value: {
          userUnits: this.convertToUser(params.outletTemp.value, 'temperature', this.parameters.outletTemp.physicalUnitId),
          commonUnits: params.outletTemp.value
        },
        entered: params.outletTemp.entered,
        calculated: params.outletTemp.calculated,
        physicalUnitId: this.parameters.outletTemp.physicalUnitId
      },
      maxPressureDrop: {
        value: {
          userUnits: this.convertToUser(params.maxPressureDrop.value, 'pressure', this.parameters.maxPressureDrop.physicalUnitId),
          commonUnits: params.maxPressureDrop.value
        },
        entered: params.maxPressureDrop.entered,
        calculated: params.maxPressureDrop.calculated,
        physicalUnitId: this.parameters.maxPressureDrop.physicalUnitId
      }
    };

    if (result.massFlowRate.value.userUnits)
      result.massFlowRate.value.userUnits = Number(result.massFlowRate.value.userUnits.toFixed(1));
    if (result.inletTemp.value.userUnits)
      result.inletTemp.value.userUnits = Number(result.inletTemp.value.userUnits.toFixed(1));
    if (result.outletTemp.value.userUnits)
      result.outletTemp.value.userUnits = Number(result.outletTemp.value.userUnits.toFixed(1));


    result.liquid.selectable = true;
    return result;
  };

  private setPressureDefaultValue(): void {

    /**
     * Default value of pressure drop in `Pa`.
     */
    const defaultValue: number = 50000;

    this.parameters.maxPressureDrop.value.commonUnits = defaultValue;
    this.parameters.maxPressureDrop.value.userUnits = this.convertToUser(defaultValue, 'pressure', this.parameters.maxPressureDrop.physicalUnitId);
    this.parameters.maxPressureDrop.value.userUnits = Number(this.parameters.maxPressureDrop.value.userUnits.toFixed(1));

    this.parameters.maxPressureDrop.entered = true;
    this.parameters.maxPressureDrop.calculated = false;

  };

  /** Method to get translation of fluid saved in duty requirement. */
  private vmGetTranslationOfSavedFluid(savedFluid: IFluid & { name: string; selectable: boolean; }): void {
    this.apiService.getTranslation([savedFluid.fluidNameId]).subscribe({
      next: (translation) => {
        const lang: string = this.stateService.get('lang').toUpperCase();

        const name: string =
          translation.find((_x) => _x.translationId === savedFluid.fluidNameId && _x.languageId === lang)?.value ||
          translation.find((_x) => _x.translationId === savedFluid.fluidNameId && _x.languageId === 'EN')?.value;

        savedFluid.name = name;
        this.onLiquidSelect(savedFluid);
      },
      error: (error) => {
        this.snackBarService.error('Can`t get translation of saved fluid.');
      }
    })

  };
}
