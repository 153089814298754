<!-- cleanability-comp start -->
<div class="cleanability-comp">
  <!-- main-container start -->
  <div class="main-container">
    <!-- toolbar start -->
    <app-toolbar>
      <button class="back-btn" (click)="back()">Back</button>
      <button class="skip margin-left-auto" (click)="skipGuide()">{{'journey.skipAssistant'|translate}}</button>
    </app-toolbar>
    <!-- toolbar end -->

    <!-- progressbar start -->
    <app-progressbar [progressStep]="5"></app-progressbar>
    <!-- progressbar end -->

    <!-- page-title start -->
    <div class="page-title">{{'cleanabilityPage.title'|translate}}</div>
    <!-- page-title end -->

    <!-- parameters-container start -->
    <div class="parameters-container" [ngClass]="{'disabled-overlay': quotationStatus}">

      <!-- column start -->
      <div class="column param-title">

        <!-- parameter-title start -->
        <div class="parameter-title">{{'commonLabels.yes'|translate}}</div>
        <!-- parameter-title end -->
        <!-- parameter-title start -->
        <div class="parameter-title">{{'commonLabels.no'|translate}}</div>
        <!-- parameter-title end -->

      </div>
      <!-- column end -->

      <!-- column start -->
      <div class="column warm">
        <!-- column-title start -->
        <div class="column-title">{{'commonLabels.warmSide'|translate}}</div>
        <!-- column-title end -->

        <!-- parameter-block start -->
        <div class="parameter-block">
          <input type="radio" name="warmCleanability" value="yes" id="warmCleanabilityYes" [(ngModel)]="warmParamValue">
          <label for="warmCleanabilityYes"></label>
        </div>
        <!-- parameter-block end -->
        <!-- parameter-block start -->
        <div class="parameter-block">
          <input type="radio" name="warmCleanability" value="no" id="warmCleanabilityNo" [(ngModel)]="warmParamValue">
          <label for="warmCleanabilityNo"></label>
        </div>
        <!-- parameter-block end -->

      </div>
      <!-- column end -->

      <!-- column start -->
      <div class="column cold">
        <!-- column-title start -->
        <div class="column-title">{{'commonLabels.coldSide'|translate}}</div>
        <!-- column-title end -->

        <!-- parameter-block start -->
        <div class="parameter-block">
          <input type="radio" name="coldCleanability" value="yes" id="coldCleanabilityYes" [(ngModel)]="coldParamValue">
          <label for="coldCleanabilityYes"></label>
        </div>
        <!-- parameter-block end -->
        <!-- parameter-block start -->
        <div class="parameter-block">
          <input type="radio" name="coldCleanability" value="no" id="coldCleanabilityNo" [(ngModel)]="coldParamValue">
          <label for="coldCleanabilityNo"></label>
        </div>
        <!-- parameter-block end -->

      </div>
      <!-- column end -->

    </div>
    <!-- parameters-container end -->

    <!-- btn-new start -->
    <button type="button" class="btn" [ngClass]="{'disabled-btn': quotationStatus}" (click)="onContinue()"
      [disabled]="quotationStatus">{{'journey.continue'|translate}}</button>
    <!-- btn-new end -->

  </div>
  <!-- main-container end -->
</div>
<!-- cleanability-comp end -->

<!-- zilo-hints start -->
<app-zilo-hints [itemStatus]="5"></app-zilo-hints>
<!-- zilo-hints end -->
