<!-- assistant-country-comp start -->
<div class="assistant-country-comp">
  <!-- main-container start -->
  <div class="main-container">
    <!-- toolbar start -->
    <app-toolbar>
      <button class="skip margin-left-auto" (click)="skipGuide()">{{'journey.skipAssistant'|translate}}</button>
    </app-toolbar>
    <!-- toolbar end -->

    <!-- progressbar start -->
    <app-progressbar [progressStep]="1"></app-progressbar>
    <!-- progressbar end -->

    <!-- page-title start -->
    <div class="page-title">{{'countryPage.title'|translate}}</div>
    <!-- page-title end -->
    <div [ngClass]="{'disabled-overlay': quotationOrdered}">

      <!-- field start -->
      <div class="field-select">
        <select id="country" name="country" [(ngModel)]="selectedCountry" (change)="countryNotSelected = false;">
          <option value="null">Country</option>
          <option *ngFor="let country of countries" [value]="country.countryId">{{country.title}}</option>
        </select>
      </div>
      <!-- field end -->

      <!-- erro-message  start -->
      <div class="error-message" *ngIf="countryNotSelected">{{'journey.makeYourChoice'|translate}}</div>
      <!-- erro-message  end -->

      <!-- btn-new start -->
      <button type="button" class="btn" (click)="onContinue()">{{'journey.continue'|translate}}</button>
      <!-- btn-new end -->

    </div>

  </div>
  <!-- main-container end -->
</div>
<!-- assistant-country-comp end -->

<!-- zilo-hints start -->
<app-zilo-hints [itemStatus]="1"></app-zilo-hints>
<!-- zilo-hints end -->
