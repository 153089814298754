<!-- home page component start -->
<div class="homepage-comp">
  <!-- header start -->
  <app-header>
    <app-usermenu></app-usermenu>
  </app-header>
  <!-- header end -->

  <!-- main container start -->
  <div class="main-container">
    <!-- progress bar start -->
    <app-progressbar></app-progressbar>
    <!-- progress bar end -->


    <!-- top-panel start -->
    <div class="top-panel">
      <!-- for admin only -->
      <div class="admin-panel" *ngIf="user.roles.includes(Role.Administrator)">
        <div class="project-id">
          <input type="text" placeholder="Enter project ID" [(ngModel)]="projectId">
        </div>
        <button type="button" class="btn-open" (click)="openProject()">Open</button>
      </div>
      <!-- end -->
      <!-- title start -->
      <div class="title">{{'homePage.myProjects'|translate}}</div>
      <!-- title end -->

      <!-- btn-new start -->
      <div class="new-project-panel">
        <button type="button" class="btn-new" (click)="newDesignModal = true;">{{'homePage.newProject'|translate}}</button>
      </div>
      <!-- btn-new end -->

    </div>
    <!-- top-panel end -->

    <!-- projects-container start -->
    <div class="projects-container">
      <!-- project start -->
      <div class="project" *ngFor="let quotation of quotationsSummary" [ngClass]="{'active': quotation.open}">
        <!-- project-info start -->
        <div class="project-info">
          <!-- project-title start -->
          <div class="item item-title">{{quotation.quotationTitle}}</div>
          <!-- project-title end -->
          <!-- project-title start -->
          <div class="item item-id">ID {{quotation.quotationNumber}}</div>
          <!-- project-title end -->
          <!-- project-title start -->
          <div class="item item-timecreated">{{'homePage.createdOn'|translate}} {{quotation.dateCreated}}</div>
          <!-- project-title end -->
          <!-- project-title start -->
          <div class="item item-timemodified">{{'homePage.lastEditOn'|translate}} {{quotation.dateLastEdit}}</div>
          <!-- project-title end -->
          <!-- project-title start -->
          <div class="item item-price">{{quotation.priceSummary|number:'1.0-0'}}
            {{getCurrencySign(quotation.idCurrency)}}</div>
          <!-- project-title end -->
          <!-- project-toggle start -->
          <div class="item item-toggle" (click)="quotation.open = !quotation.open">
            <img *ngIf="quotation.open" src="../../../assets/icons/arrow_down_green.png" alt="arrow_down_green.png">
            <img *ngIf="!quotation.open" src="../../../assets/icons/arrow_up_green.png" alt="arrow_down_green.png">
          </div>
          <!-- project-toggle end -->
        </div>
        <!-- project-info end -->

        <!-- project-detailes start -->
        <div class="project-detailes">
          <!-- items-container start -->
          <div class="item-container">

            <!-- list-header start -->
            <div class="list-header">
              <!-- position item start -->
              <div class="item item-position">Position</div>
              <!-- position item end -->

              <!-- name item start -->
              <div class="item item-name">Item</div>
              <!-- name item end -->

              <!-- description item start -->
              <div class="item item-desc">Description</div>
              <!-- description item end -->

              <!-- availability item start -->
              <div class="item item-available">Availability</div>
              <!-- availability item end -->

              <!-- unit price item start -->
              <div class="item item-unit-price">Unit price</div>
              <!-- unit price item end -->
              <!-- quantity item start -->
              <div class="item item-quantity">Quantity</div>
              <!-- quantity item end -->
              <!-- priceitem start -->
              <div class="item item-price">Price</div>
              <!-- priceitem end -->
            </div>
            <!-- list-header end -->

            <!-- items-list start -->
            <div class="items-list">
              <!-- item start -->
              <div class="item" *ngFor="let item of quotation.items">
                <div class="navigation-wrapper" (click)="navigateToItem(item)">
                  <!-- position item start -->
                  <div class="block block-position">{{item.itemNumber}}</div>
                  <!-- position item end -->

                  <!-- name item start -->
                  <div class="block block-name">{{item.itemTitle}}</div>
                  <!-- name item end -->

                  <!-- description item start -->
                  <div class="block block-desc">{{item.itemDescription}}</div>
                  <!-- description item end -->

                  <!-- availability item start -->
                  <div class="block block-available">{{getAvailability(item.availability)}}</div>
                  <!-- availability item end -->

                  <!-- unit price item start -->
                  <div class="block block-unit-price">{{item.unit_price|number:'1.0-0'}}
                    {{getCurrencySign(quotation.idCurrency)}}
                  </div>
                  <!-- unit price item end -->
                </div>
                <!-- quantity item start -->
                <div class="block block-quantity">
                  <!-- input-quantity start -->
                  <input class="input-quantity" type="text" name="quantity" id="quantity"
                    [(ngModel)]="item.itemQuantity" (ngModelChange)="inItemQuantityChange(item, quotation, $event)">
                  <!-- input-quantity end -->
                </div>
                <!-- quantity item end -->

                <!-- price item start -->
                <div class="block block-price">{{item.itemQuantity*item.unit_price|number:'1.0-0'}}
                  {{getCurrencySign(quotation.idCurrency)}}</div>
                <!-- price item end -->

                <!-- copy-item start -->
                <div class="block block-delete" (click)="duplicateItemModal = true;duplicateItemId = item.itemId;"
                  matTooltip="Copy Item">
                  <img src="../../../assets/icons/copy_icon.png" alt="copy_icon.png">
                </div>
                <!-- copy-item end -->
                <!-- datasheet-item start -->
                <div class="block block-datasheet" (click)="getDatasheet(item)">
                  <img src="../../../assets/icons/download_icon.png" alt="download_icon.png"
                    matTooltip="Download Datasheet">

                  <!-- spenner-wrapper start -->
                  <div class="spinner-wrapper" *ngIf="item.spinner">
                    <mat-spinner [diameter]="12"></mat-spinner>
                  </div>
                  <!-- spenner-wrapper end -->
                </div>
                <!-- datasheet-item end -->
                <!-- delete-item start -->
                <div class="block block-delete" [ngClass]="{'disable': quotation.items.length<=1}"
                  (click)="deleteItemModal = true;deleteItemId = item.itemId;" matTooltip="Delete Item">
                  <img src="../../../assets/icons/delete_icon.png" alt="delete_icon.png">
                </div>
                <!-- delete-item end -->

              </div>
              <!-- item end -->
            </div>
            <!-- items-list end -->

          </div>
          <!-- items-container end -->

          <!-- project-menu-container start -->
          <div class="project-menu-container">

            <!-- btn-add-item start -->
            <button type="button" class="btn-add-item"
              (click)="addItemDesignModal = true; addItemQuotationId = quotation.quotationId;">Add Item</button>
            <!-- btn-add-item end -->

            <!-- menu start -->
            <div class="menu">
              <!-- menu-item start -->
              <div class="menu-item menu-item-tg" (click)="vmOpenSendOrderModal(quotation.quotationId)"
                matTooltip="{{'homePage.sendOrder'|translate}}">
                <img src="../../../assets/icons/telegram_icon.png" alt="telegram_icon.png">
              </div>
              <!-- menu-item end -->
              <!-- menu-item start -->
              <div class="menu-item menu-item-copy"
                (click)="duplicateProjectModal = true;duplicateProjectId = quotation.quotationId;"
                matTooltip="Copy Project">
                <img src="../../../assets/icons/copy_icon.png" alt="copy_icon.png">
              </div>
              <!-- menu-item end -->
              <!-- menu-item start -->
              <!--              <div class="menu-item menu-item-download" (click)="generateQuoteReport(quotation.quotationId)">-->
              <!--                <img src="../../../assets/icons/download_icon.png" alt="download_icon.png">-->
              <!--              </div>-->
              <!-- menu-item end -->
              <!-- menu-item start -->
              <div class="menu-item menu-item-delete"
                (click)="deleteProjectModal = true; deleteItemId = quotation.quotationId;" matTooltip="Delete Project">
                <img src="../../../assets/icons/delete_icon.png" alt="delete_icon.png">
              </div>
              <!-- menu-item end -->
            </div>
            <!-- menu end -->

          </div>
          <!-- project-menu-container end -->

        </div>
        <!-- project-detailes end -->

      </div>
      <!-- project end -->
    </div>
    <!-- projects-container end -->

    <!-- pagination-container start -->
    <div class="pagination-container">
      <!-- label start -->
      <label for="pages">{{'homePage.page'|translate}}</label>
      <!-- label end -->

      <!-- select start -->
      <select id="pages" name="pages" [(ngModel)]="currentPage" (change)="changePage()">
        <option *ngFor="let page of pages" [value]="page">{{page}}</option>
      </select>
      <!-- select end -->
    </div>
    <!-- pagination-container end -->

  </div>
  <!-- main container end -->

  <!-- footer start -->
  <app-footer></app-footer>
  <!-- footer end -->
</div>
<!-- home page component end -->

<!-- item delete modal start -->
<!-- delete-modal-overlay start -->
<div class="delete-modal-overlay" *ngIf="deleteItemModal">
  <!-- modal-container start -->
  <div class="modal-container">
    <!-- modal-message start -->
    <div class="modal-message">Are you sure that you want to delete the item?</div>
    <!-- modal-message end -->
    <!-- modal-btns-row start -->
    <div class="modal-btns-row">
      <button type="button" class="btn" (click)="cancelDelete()">{{'homePage.cancelButton'|translate}}</button>
      <button type="button" class="btn btn-green" (click)="deleteItem()">Delete</button>
    </div>
    <!-- modal-btns-row end -->
  </div>
  <!-- modal-container end -->
</div>
<!-- delete-modal-overlay end -->
<!-- item delete modal end -->

<!-- project delete modal start -->
<!-- delete-modal-overlay start -->
<div class="delete-modal-overlay" *ngIf="deleteProjectModal">
  <!-- modal-container start -->
  <div class="modal-container">
    <!-- modal-message start -->
    <div class="modal-message">Are you sure that you want to delete the project?</div>
    <!-- modal-message end -->
    <!-- modal-btns-row start -->
    <div class="modal-btns-row">
      <button type="button" class="btn" (click)="cancelDelete()">{{'homePage.cancelButton'|translate}}</button>
      <button type="button" class="btn btn-green" (click)="deleteQuotation()">Delete</button>
    </div>
    <!-- modal-btns-row end -->
  </div>
  <!-- modal-container end -->
</div>
<!-- delete-modal-overlay end -->
<!-- project delete modal end -->

<!-- duplicate item modal start -->
<!-- new-design-modal-overlay start -->
<div class="new-design-modal-overlay" *ngIf="duplicateItemModal">
  <!-- new-design-modal start -->
  <div class="new-design-modal">
    <!-- title start -->
    <div class="title">Duplicate Item</div>
    <!-- title end -->
    <!-- new-design-form start -->
    <form class="new-design-form">
      <!-- field start -->
      <div class="field">
        <label for="duplicateItemTitle">New Item Title:</label>
        <input type="text" name="duplicateItemTitle" id="duplicateItemTitle" [(ngModel)]="duplicateItemTitle">
      </div>
      <!-- field end -->
      <!-- field start -->
      <div class="field description">
        <label for="duplicateItemDescription">New Item Description:</label>
        <textarea name="duplicateItemDescription" id="duplicateItemDescription"
          [(ngModel)]="duplicateItemDesc"></textarea>
      </div>
      <!-- field end -->

      <!-- not equal passwords message start -->
      <div class="invalidMsg" *ngIf="duplicateItemValidationError">Enter Item title</div>
      <!-- not equal passwords message end -->

      <!-- buttons-block start -->
      <div class="buttons-block">
        <!-- button start -->
        <button type="button" class="btn" (click)="cancelDuplicate()">{{'homePage.cancelButton'|translate}}</button>
        <!-- button end -->
        <!-- button start -->
        <button type="button" class="btn btn-green" (click)="duplicateItem()">Duplicate</button>
        <!-- button end -->
      </div>
      <!-- buttons-block end -->
    </form>
    <!-- new-design-form end -->
  </div>
  <!-- new-design-modal end -->
</div>
<!-- new-design-modal-overlay end -->
<!-- duplicate item modal end -->

<!-- duplicate project modal start -->
<!-- new-design-modal-overlay start -->
<div class="new-design-modal-overlay" *ngIf="duplicateProjectModal">
  <!-- new-design-modal start -->
  <div class="new-design-modal">
    <!-- title start -->
    <div class="title">Duplicate Project</div>
    <!-- title end -->
    <!-- new-design-form start -->
    <form class="new-design-form">
      <!-- field start -->
      <div class="field">
        <label for="duplicateProjectTitle">{{'homePage.projectTitle'|translate}}:</label>
        <input type="text" name="duplicateProjectTitle" id="duplicateProjectTitle" [(ngModel)]="duplicateProjectTitle">
      </div>
      <!-- field end -->

      <!-- not equal passwords message start -->
      <div class="invalidMsg" *ngIf="newDesignValidationError">Enter Project title</div>
      <!-- not equal passwords message end -->

      <!-- buttons-block start -->
      <div class="buttons-block">
        <!-- button start -->
        <button type="button" class="btn btn-green" (click)="cancelProjectDuplicate()">{{'homePage.cancelButton'|translate}}</button>
        <!-- button end -->
        <!-- button start -->
        <button type="submit" class="btn btn-green" (click)="duplicateProject()">Duplicate</button>
        <!-- button end -->
      </div>
      <!-- buttons-block end -->
    </form>
    <!-- new-design-form end -->
  </div>
  <!-- new-design-modal end -->
</div>
<!-- new-design-modal-overlay end -->
<!-- duplicate project modal end -->

<!-- new-design-modal-overlay start -->
<div class="new-design-modal-overlay" *ngIf="newDesignModal">
  <!-- new-design-modal start -->
  <div class="new-design-modal">
    <!-- title start -->
    <div class="title">{{'homePage.newProject'|translate}}</div>
    <!-- title end -->
    <!-- new-design-form start -->
    <form class="new-design-form">
      <!-- field start -->
      <div class="field">
        <label for="projectTitle">{{'homePage.projectTitle'|translate}}:</label>
        <input type="text" name="projectTitle" id="projectTitle" [(ngModel)]="newProjectTitle">
      </div>
      <!-- field end -->
      <!-- field start -->
      <div class="field">
        <label for="itemTitle">{{'homePage.itemTitle'|translate}}:</label>
        <input type="text" name="itemTitle" id="itemTitle" [(ngModel)]="newItemTitle">
      </div>
      <!-- field end -->
      <!-- field start -->
      <div class="field description">
        <label for="itemDescription">{{'homePage.itemDescription'|translate}}:</label>
        <textarea name="itemDescription" id="itemDescription" [(ngModel)]="newItemDesc"></textarea>
      </div>
      <!-- field end -->

      <!-- not equal passwords message start -->
      <div class="invalidMsg" *ngIf="newDesignValidationError">Enter Project and Item title</div>
      <!-- not equal passwords message end -->

      <!-- buttons-block start -->
      <div class="buttons-block">
        <!-- button start -->
        <button type="button" class="btn btn-green" (click)="cancelNewDesign()">{{'homePage.cancelButton'|translate}}</button>
        <!-- button end -->
        <!-- button start -->
        <button type="submit" class="btn btn-green" (click)="createNewDesign()">OK</button>
        <!-- button end -->
      </div>
      <!-- buttons-block end -->
    </form>
    <!-- new-design-form end -->
  </div>
  <!-- new-design-modal end -->
</div>
<!-- new-design-modal-overlay end -->

<!-- add-item-modal-overlay start -->
<div class="new-design-modal-overlay" *ngIf="addItemDesignModal">
  <!-- new-design-modal start -->
  <div class="new-design-modal">
    <!-- title start -->
    <div class="title">Add Item</div>
    <!-- title end -->
    <!-- new-design-form start -->
    <form class="new-design-form">
      <!-- field start -->
      <div class="field">
        <label for="addItemTitle">{{'homePage.itemTitle'|translate}}:</label>
        <input type="text" name="addItemTitle" id="addItemTitle" [(ngModel)]="addItemTitle">
      </div>
      <!-- field end -->
      <!-- field start -->
      <div class="field description">
        <label for="addItemDescription">{{'homePage.itemDescription'|translate}}:</label>
        <textarea name="addItemDescription" id="addItemDescription" [(ngModel)]="addItemDesc"></textarea>
      </div>
      <!-- field end -->

      <!-- not equal passwords message start -->
      <div class="invalidMsg" *ngIf="addItemValidationError">Enter Item title</div>
      <!-- not equal passwords message end -->

      <!-- buttons-block start -->
      <div class="buttons-block">
        <!-- button start -->
        <button type="button" class="btn btn-green" (click)="cancelAddItem()">{{'homePage.cancelButton'|translate}}</button>
        <!-- button end -->
        <!-- button start -->
        <button type="submit" class="btn btn-green" (click)="addItem()">OK</button>
        <!-- button end -->
      </div>
      <!-- buttons-block end -->
    </form>
    <!-- new-design-form end -->
  </div>
  <!-- new-design-modal end -->
</div>
<!-- add-item-modal-overlay end -->

<!-- journey-modal-overlay start -->
<div class="journey-modal-overlay" *ngIf="guideModal">
  <!-- journey-modal start -->
  <div class="journey-modal">
    <!-- title start -->
    <div class="title-container">
      <div class="title">{{'homePage.journeyQuestion'|translate}}</div>
    </div>
    <!-- title end -->

    <!-- buttons-block start -->
    <div class="buttons-block">
      <!-- button start -->
      <button type="button" class="btn btn-green" (click)="startGuide()">{{'homePage.yes'|translate}}</button>
      <!-- button end -->

      <!-- button start -->
      <button type="submit" class="btn btn-green" (click)="cancelGuide()">{{'homePage.skip'|translate}}</button>
      <!-- button end -->
    </div>
    <!-- buttons-block end -->
  </div>
  <!-- journey-modal end -->
</div>
<!-- journey-modal-overlay end -->

<!-- send-order-modal start -->
<div class="send-order-modal" *ngIf="sendOrderModal">
  <!-- send-order-modal-container start -->
  <div class="send-order-modal-container">
    <!-- send-order-modal-title start -->
    <div class="send-order-modal-title">{{'sendOrder.notes'|translate}}</div>
    <!-- send-order-modal-title end -->

    <!-- send-order-modal-form-notes start -->
    <form class="send-order-modal-form-notes" [formGroup]="sendOrderFormNotes" (submit)="vmSendOrder()">
      <!-- textarea start -->
      <textarea name="notes" class="textarea-notes" formControlName="notes"></textarea>
      <!-- textarea end -->

      <!-- not equal passwords message start -->
      <div class="validation-error" *ngIf="sendOrderFormNotes.invalid && sendOrderFormNotes.dirty">
        {{'sendOrder.notesRequired'|translate}}
      </div>
      <!-- not equal passwords message end -->

      <!-- btns-container start -->
      <div class="btns-container">

        <!-- button start -->
        <button type="button" class="btn" (click)="vmCloseSendOrderModal()">{{'commonLabels.cancelButton'|translate}}</button>
        <!-- button end -->

        <!-- button start -->
        <button type="submit" class="btn btn-green">{{'sendOrder.order'|translate}}</button>
        <!-- button end -->

      </div>
      <!-- btns-container end -->


    </form>
    <!-- send-order-modal-form-notes end -->

  </div>
  <!-- send-order-modal-container end -->
</div>
<!-- send-order-modal end -->
