<!-- login component start -->
<ng-container>
  <!-- header start -->
  <app-header>
    <app-language-menu></app-language-menu>
  </app-header>
  <!-- header end -->

  <!-- subtitle start -->
  <div class="header-subtitle">The Future of Engineering</div>
  <!-- subtitle end -->

  <!-- content start -->
  <div class="jumbotron">
    <!-- main container start -->
    <div class="main-container">
      <!-- content  start -->
      <div class="content">

        <!-- title-wrapper start -->
        <div class="title-wrapper">
          <!-- title start -->
          <div class="title">
            {{'login.slogan'|translate}}
          </div>
          <!-- title end -->
        </div>
        <!-- title-wrapper end -->

        <!-- image start -->
        <img src="../../../assets/images/jumbotron_image.png" alt="jumbotron_image.png" class="jumbotron-image">
        <!-- image end -->

      </div>
      <!-- content  end -->
    </div>
    <!-- main container end -->
  </div>
  <!-- content end -->

  <!-- form section start -->
  <div class="form-section">
    <!-- main container start -->
    <div class="main-container">
      <!-- form title start -->
      <div class="form-title">Log-In</div>
      <!-- form title end -->

      <!-- form login start -->
      <form class="login-form" #loginForm="ngForm" (ngSubmit)="login(loginForm)">

        <!-- email field start -->
        <div class="field">
          <!--input start-->
          <input type="email" id="email" name='username' [(ngModel)]="credentials.username" placeholder="E-Mail"
            required>
          <!--input end-->
        </div>
        <!-- email field end -->

        <!-- password field start -->
        <div class="field">
          <!--input start-->
          <input type="password" id="password" name='password' [(ngModel)]="credentials.password" placeholder="Password"
            requires>
          <!--input end-->
        </div>
        <!-- password field end -->

        <!-- button block start-->
        <div class="button-block">
          <!-- button start -->
          <button type="submit" class="btn-login" [disabled]="!loginForm.valid"></button>
          <!-- button end -->
        </div>
        <!-- button block end-->

        <!-- invalid message start -->
        <div class="invalid-msg" *ngIf="invalidLogin">Invalid username or password.</div>
        <!-- invalid message end -->

      </form>
      <!-- form login end -->

    </div>
    <!-- main container end -->
  </div>
  <!-- form section end -->

  <!-- additional links section start -->
  <div class="additional-links-section">
    <!-- main container start -->
    <div class="main-container">
      <!-- additionals block start -->
      <div class="additionals-block">
        <!-- link start -->
        <a href="/forgotpassword" rel="noopener noreferrer"
          class="link-item link-item__password">{{'login.forgotPassword' | translate}}</a>
        <!-- link end -->

        <div class="text">{{'login.noPassword' | translate}}</div>
        <div class="text">
          {{'login.then' | translate}} <a routerLink='/registration' rel="noopener noreferrer"
            class="link-item">{{'login.link' | translate}}</a>
        </div>
      </div>
      <!-- additionals block end -->
    </div>
    <!-- main container end -->
  </div>
  <!-- additional links section end -->

  <!-- footer start -->
  <footer class="footer">
    <!-- content block start -->
    <div class="content-block">

    </div>
    <!-- content block end -->
  </footer>
  <!-- footer end -->


  <!-- user section start -->
  <div class="user-section" *ngIf="showUserSelection">
    <!-- overlay start -->
    <div class="users-block">
      <!-- arrow left start -->
      <div class="arrow arrow-left"></div>
      <!-- arrow left end -->

      <!-- users wrapper start -->
      <div class="users-wrapper" [ngClass]="{'center': users.length === 2}">
        <!-- user start -->
        <div class="user" *ngFor="let user of users" (click)="selectUser(user);">
          <!-- users profile image start -->
          <div class="image">
            <img src="../../../assets/images/user_icon.png" alt="user photo" *ngIf="!user.photo">
            <img src="data:application/octet-stream;base64,{{user.photo}}" alt="user photo" *ngIf="user.photo">
          </div>
          <!-- users profile image end -->
          <!-- users name start -->
          <div class="name">{{user.name}} {{user.surname}}</div>
          <!-- users name end -->
        </div>
        <!-- user end -->
      </div>
      <!-- users wrapper end -->

      <!-- arrow left start -->
      <div class="arrow arrow-right"></div>
      <!-- arrow left end -->
    </div>
    <!-- overlay end -->
  </div>
  <!-- user section end -->


</ng-container>
<!-- login component end -->
