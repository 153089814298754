<!-- forgot password component start -->
<div class="forgot-password-comp">
  <!-- header start -->
  <app-header>
    <app-language-menu></app-language-menu>
  </app-header>
  <!-- header end -->

  <!-- devider start -->
  <div class="devider">
    <div class="main-container">
      <app-progressbar></app-progressbar>
    </div>
  </div>
  <!-- devider end -->




  <!-- main container start -->
  <div class="main-container">
    <!-- forgot password form start -->
    <form class="forgot-password" *ngIf="!forgotPasswordFormSubmited">
      <!-- title start -->
      <div class="forgot-password_title">{{'forgotPasswordPage.title' | translate}}</div>
      <!-- title end -->


      <!-- form content start -->
      <div class="forgot-password-content">

        <!-- field start -->
        <div class="field">
          <label for="email">{{'forgotPasswordPage.email' | translate}}:</label>
          <input type="email" name="email" id="email" [(ngModel)]="email" patterns="^[^\s@]+@[^\s@]+\.[^\s@]+$"
            required>
        </div>
        <!-- field end -->

        <!-- buttons block start -->
        <div class="buttons-block">
          <!-- button start -->
          <a class="btn btn-cancel" (click)="cancel($event);">{{'forgotPasswordPage.cancelButton' | translate}}</a>
          <!-- button end -->
          <!-- button start -->
          <button type="submit" class="btn btn-register" (click)="submitForgotPassword($event)" >OK</button>
          <!-- button end -->
        </div>
        <!-- buttons block end -->

      </div>
      <!-- form content end -->

      <!-- form validation errors start -->
      <div class="forgot-password-validation-errors">
        <!-- not equal passwords message start -->
        <div class="invalidMsg" *ngIf="emailNotValid">{{'registration.form.validationMsg.emailNotValid'|translate}}
        </div>
        <!-- not equal passwords message end -->
      </div>
      <!-- form validation errors end -->


    </form>
    <!-- forgot password form end -->

    <!-- message after form submited start -->
    <div class="forgot-password-submited-msg" *ngIf="forgotPasswordFormSubmited">{{'forgotPasswordPage.confirmMessage' | translate}}</div>
    <!-- message after form submited end -->

  </div>
  <!-- main container end -->

</div>
<!-- forgot password component end -->
