import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-duty-validation',
  templateUrl: './duty-validation.component.html',
  styleUrls: ['./duty-validation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DutyValidationComponent implements OnInit, OnChanges {

  /** Flag to indicate that some error occured. */
  public validationError: boolean = false;

  /** Message that some liquid not selected */
  public liquidNotSelectedErrorMsg: string = null;
  /** Cannot solve underdetermined system - more parameters should be specified */
  public underdeterminedErrorNeedMoreParams: boolean = false;
  /**Cannot solve underdetermined system - calculated parameters should be on different sides */
  public underdeterminedErrorDiffSides: boolean = false;
  /**Overdetermined system - too many parameters have been specified */
  public overdeterminedErrorTooManyParams: boolean = false;

  /** Condensation */

  /** Saturation temperature/pressure is not entered */
  public saturationParamsNotEntered: boolean = false;
  /** Outlet temperature is not entered the warm side */
  public outletTempWarmNotEntered: boolean = false;

  /** Gas */

  /** Inlet Pressure is not entered */
  public inletPressureWarmNotEntered: boolean = false;
  public inletPressureColdNotEntered: boolean = false;

  /**
   * List of validations errors for `Condensation and Evaporation`.
   */
  public validationsErrorsListOfTemp: string[] = [];

  // Validation errors after calculation
  public warmFluidTempNotInRangeWarning: boolean = false;
  public warmMassFlowRateError: boolean = false;
  public warmMaxPressureDropError: boolean = false;

  public coldMassFlowRateError: boolean = false;
  public coldFluidTempNotInRangeWarning: boolean = false;
  public coldMaxPressureDropError: boolean = false;

  public notAcceptableTemperature: boolean = false;
  public notValidOverdesign: boolean = false;
  public notValidColdMaxTemperature: boolean = false;
  public notValidWarmMaxTemperature: boolean = false;
  public notValidInletTemperatures: boolean = false;
  public notValidInletOutletTemperaturesCond: boolean = false;
  public notValidInletOutletTemperaturesEvap: boolean = false;
  public notValidColdMaxPressure: boolean = false;
  public notValidWarmMaxPressure: boolean = false;

  @Input()
  public warmAppType: string = null;
  @Input()
  public coldAppType: string = null;
  @Input()
  public parametersEntered: { warm: any; cold: any; } = null;
  @Input()
  public parametersCalculated: { warm: any; cold: any; } = null;
  @Input()
  public heatExchangeRate: { value: { userUnits: any; commonUnits: any; }; entered: boolean; calculated: boolean; } = null;
  @Input()
  public overdesign: number = null;
  @Input()
  public designParameters: { cold: { max_pressure: number; max_temperature: number; }; warm: { max_pressure: number; max_temperature: number; } } = null;
  @Input()
  public extraDuty: boolean = false;

  @Output()
  public enteredParamsValidated: EventEmitter<{ valid: boolean }> = new EventEmitter();
  @Output()
  public calculatedParamsValidated: EventEmitter<{ valid: boolean }> = new EventEmitter();

  constructor() {
    // empty
  };

  public ngOnInit() { };
  public ngOnChanges(changes: SimpleChanges): void {
    if ((changes.parametersEntered && !changes.parametersEntered.firstChange) || (changes.heatExchangeRate && !changes.heatExchangeRate.firstChange)) {
      this.vmValidateEnteredParams();
    };
    if ((changes.parametersCalculated && !changes.parametersCalculated.firstChange) || (changes.designParameters && !changes.designParameters.firstChange)) {
      this.vmValidationAfterCalculation();
    };

  };

  private vmValidateEnteredParams() {
    // Reset flags
    this.vmResetAllFlags();

    const warmLiquid: string = this.parametersEntered.warm ? this.parametersEntered.warm.liquid.name : null;
    const coldLiquid: string = this.parametersEntered.cold ? this.parametersEntered.cold.liquid.name : null;

    // Check that liquid entered.
    if (!warmLiquid && !coldLiquid) {
      this.validationError = true;
      this.liquidNotSelectedErrorMsg = 'Fluid is not entered on both sides';
      this.enteredParamsValidated.emit({ valid: false });
      return;
    } else if (!warmLiquid) {
      this.validationError = true;
      this.liquidNotSelectedErrorMsg = 'Fluid is not entered on warm side';
      this.enteredParamsValidated.emit({ valid: false });
      return;
    } else if (!coldLiquid) {
      this.validationError = true;
      this.liquidNotSelectedErrorMsg = 'Fluid is not entered on cold side';
      this.enteredParamsValidated.emit({ valid: false });
      return;
    };

    // Validate some params for Condensation
    if (this.warmAppType === 'CO') {

      if (!this.parametersEntered.warm.satTemp.value.commonUnits && !this.parametersEntered.warm.satTemp.entered) {
        this.validationError = true;
        this.saturationParamsNotEntered = true;
        this.enteredParamsValidated.emit({ valid: false });
        return;
      };

      if (!this.parametersEntered.warm.satPressure.value.commonUnits && !this.parametersEntered.warm.satPressure.entered) {
        this.validationError = true;
        this.saturationParamsNotEntered = true;
        this.enteredParamsValidated.emit({ valid: false });
        return;
      };

      // Do not validate this parameter for extra duty
      if (!this.extraDuty) {
        if (!this.parametersEntered.warm.outletTemp.value.commonUnits && !this.parametersEntered.warm.outletTemp.entered) {
          this.validationError = true;
          this.outletTempWarmNotEntered = true;
          this.enteredParamsValidated.emit({ valid: false });
          return;
        };
      };

    };

    // Validate some parameters for Evaporation
    if (this.coldAppType === 'EV') {
      if (!this.parametersEntered.cold.satTemp.value.commonUnits && !this.parametersEntered.cold.satTemp.entered) {
        this.validationError = true;
        this.saturationParamsNotEntered = true;
        this.enteredParamsValidated.emit({ valid: false });
        return;
      };

      if (!this.parametersEntered.cold.satPressure.value.commonUnits && !this.parametersEntered.cold.satPressure.entered) {
        this.validationError = true;
        this.saturationParamsNotEntered = true;
        this.enteredParamsValidated.emit({ valid: false });
        return;
      };
    };

    // Validate some parameters for Gas
    const warmInletPressure = this.parametersEntered.warm.inletPressure;
    const coldInletPressure = this.parametersEntered.cold.inletPressure;

    if (this.warmAppType === 'GA' && !warmInletPressure.entered) {
      this.validationError = true;
      this.inletPressureWarmNotEntered = true;
      this.enteredParamsValidated.emit({ valid: false });
      return;
    };

    if (this.coldAppType === 'GA' && !coldInletPressure.entered) {
      this.validationError = true;
      this.inletPressureColdNotEntered = true;
      this.enteredParamsValidated.emit({ valid: false });
      return;
    };


    // WARM Side
    let warmEnteredParams: number = null;
    let warmEmptyParams: number = null;
    let warmCalculatedParams: number = null;

    // COLD SIde
    let coldEnteredParams: number = null;
    let coldEmptyParams: number = null;
    let coldCalculatedParams: number = null;


    if (this.extraDuty) {
      // WARM
      if (this.warmAppType === 'LI' || this.warmAppType === 'GA') {
        warmEnteredParams = [this.parametersEntered.warm.massFlowRate, this.parametersEntered.warm.inletTemp].filter((x) => (x.value.commonUnits || x.value.commonUnits === 0) && x.entered).length;
        warmEmptyParams = [this.parametersEntered.warm.massFlowRate, this.parametersEntered.warm.inletTemp].filter((x) => !x.value.commonUnits && !x.entered && !x.calculated).length;
        warmCalculatedParams = [this.parametersEntered.warm.massFlowRate, this.parametersEntered.warm.inletTemp].filter((x) => x.value.commonUnits && x.calculated).length;
      } else if (this.warmAppType === 'CO') {
        warmEnteredParams = [this.parametersEntered.warm.outletTemp, this.parametersEntered.warm.satTemp, this.parametersEntered.warm.satPressure].filter((x) => (x.value.commonUnits || x.value.commonUnits === 0) && x.entered).length;
        warmEmptyParams = [this.parametersEntered.warm.outletTemp, this.parametersEntered.warm.satTemp, this.parametersEntered.warm.satPressure].filter((x) => !x.value.commonUnits && !x.entered && !x.calculated).length;
        warmCalculatedParams = [this.parametersEntered.warm.outletTemp, this.parametersEntered.warm.satTemp, this.parametersEntered.warm.satPressure].filter((x) => x.value.commonUnits && x.calculated).length;
      };

      // COLD
      if (this.coldAppType === 'LI' || this.coldAppType === 'GA') {
        coldEnteredParams = [this.parametersEntered.cold.massFlowRate, this.parametersEntered.cold.inletTemp].filter((x) => (x.value.commonUnits || x.value.commonUnits === 0) && x.entered).length;
        coldEmptyParams = [this.parametersEntered.cold.massFlowRate, this.parametersEntered.cold.inletTemp].filter((x) => !x.value.commonUnits && !x.entered && !x.calculated).length;
        coldCalculatedParams = [this.parametersEntered.cold.massFlowRate, this.parametersEntered.cold.inletTemp].filter((x) => x.value.commonUnits && x.calculated).length;
      } else if (this.coldAppType === 'EV') {
        coldEnteredParams = [this.parametersEntered.cold.inletTemp, this.parametersEntered.cold.satPressure, this.parametersEntered.cold.satTemp].filter((x) => (x.value.commonUnits || x.value.commonUnits === 0) && x.entered).length;
        coldEmptyParams = [this.parametersEntered.cold.inletTemp, this.parametersEntered.cold.satPressure, this.parametersEntered.cold.satTemp].filter((x) => !x.value.commonUnits && !x.entered && !x.calculated).length;
        coldCalculatedParams = [this.parametersEntered.cold.inletTemp, this.parametersEntered.cold.satPressure, this.parametersEntered.cold.satTemp].filter((x) => (x.value.commonUnits || x.value.commonUnits === 0) && x.calculated).length;
      };

      const allEnteredParams = warmEnteredParams + coldEnteredParams;
      const appType: string = this.warmAppType + this.coldAppType;

      if ((appType === 'LIGA' || appType === 'GALI' || appType === 'LILI' || appType === 'GAGA') && allEnteredParams != 4) {

        this.validationError = true;
        this.underdeterminedErrorNeedMoreParams = true;

      } else if ((appType === 'COLI' || appType === 'LIEV') && allEnteredParams != 5) {

        this.validationError = true;
        this.underdeterminedErrorNeedMoreParams = true;

      } else if ((appType === 'COGA' || appType === 'GAEV' || appType === 'COEV') && allEnteredParams != 6) {

        this.validationError = true;
        this.underdeterminedErrorNeedMoreParams = true;

      };

    } else {

      // WARM
      if (this.warmAppType === 'LI' || this.warmAppType === 'GA') {
        warmEnteredParams = [this.parametersEntered.warm.massFlowRate, this.parametersEntered.warm.inletTemp, this.parametersEntered.warm.outletTemp].filter((x) => (x.value.commonUnits || x.value.commonUnits === 0) && x.entered).length;
        warmEmptyParams = [this.parametersEntered.warm.massFlowRate, this.parametersEntered.warm.inletTemp, this.parametersEntered.warm.outletTemp].filter((x) => !x.value.commonUnits && !x.entered && !x.calculated).length;
        warmCalculatedParams = [this.parametersEntered.warm.massFlowRate, this.parametersEntered.warm.inletTemp, this.parametersEntered.warm.outletTemp].filter((x) => x.value.commonUnits && x.calculated).length;
      } else if (this.warmAppType === 'CO') {
        warmEnteredParams = [this.parametersEntered.warm.massFlowRate, this.parametersEntered.warm.satTemp, this.parametersEntered.warm.satPressure].filter((x) => (x.value.commonUnits || x.value.commonUnits === 0) && x.entered).length;
        warmEmptyParams = [this.parametersEntered.warm.massFlowRate, this.parametersEntered.warm.satTemp, this.parametersEntered.warm.satPressure].filter((x) => !x.value.commonUnits && !x.entered && !x.calculated).length;
        warmCalculatedParams = [this.parametersEntered.warm.massFlowRate, this.parametersEntered.warm.satTemp, this.parametersEntered.warm.satPressure].filter((x) => x.value.commonUnits && x.calculated).length;
      };

      // COLD
      if (this.coldAppType === 'LI' || this.coldAppType === 'GA') {
        coldEnteredParams = [this.parametersEntered.cold.massFlowRate, this.parametersEntered.cold.inletTemp, this.parametersEntered.cold.outletTemp].filter((x) => (x.value.commonUnits || x.value.commonUnits === 0) && x.entered).length;
        coldEmptyParams = [this.parametersEntered.cold.massFlowRate, this.parametersEntered.cold.inletTemp, this.parametersEntered.cold.outletTemp].filter((x) => !x.value.commonUnits && !x.entered && !x.calculated).length;
        coldCalculatedParams = [this.parametersEntered.cold.massFlowRate, this.parametersEntered.cold.inletTemp, this.parametersEntered.cold.outletTemp].filter((x) => x.value.commonUnits && x.calculated).length;
      } else if (this.coldAppType === 'EV') {
        coldEnteredParams = [this.parametersEntered.cold.massFlowRate, this.parametersEntered.cold.inletTemp, this.parametersEntered.cold.satPressure, this.parametersEntered.cold.satTemp].filter((x) => (x.value.commonUnits || x.value.commonUnits === 0) && x.entered).length;
        coldEmptyParams = [this.parametersEntered.cold.massFlowRate, this.parametersEntered.cold.inletTemp, this.parametersEntered.cold.satPressure, this.parametersEntered.cold.satTemp].filter((x) => !x.value.commonUnits && !x.entered && !x.calculated).length;
        coldCalculatedParams = [this.parametersEntered.cold.massFlowRate, this.parametersEntered.cold.inletTemp, this.parametersEntered.cold.satPressure, this.parametersEntered.cold.satTemp].filter((x) => (x.value.commonUnits || x.value.commonUnits === 0) && x.calculated).length;
      };


      // Heat Exchange Rate
      const heRateEmpty = !this.heatExchangeRate.entered && !this.heatExchangeRate.calculated ? 1 : 0;
      const heRateEntered = this.heatExchangeRate.entered ? 1 : 0;
      const heRateCalculated = this.heatExchangeRate.calculated ? 1 : 0;
      const allEmptyParams = warmEmptyParams + coldEmptyParams + heRateEmpty;


      const allEnteredParams = warmEnteredParams + coldEnteredParams + heRateEntered;
      const tooManyParams = allEmptyParams + warmCalculatedParams + coldCalculatedParams + heRateCalculated;

      // 1. Validation for Liquid/Liquid Application
      // 2. Validation for Condensation/Liquid Application
      // 3. Validation for Liquid/Evaporation Application
      // 4. Validation for Condensation/Evaporation Application
      if ((this.warmAppType === 'LI' && this.coldAppType === 'LI') // 4 params
        || (this.warmAppType === 'GA' && this.coldAppType === 'GA') // 6 params
        || (this.warmAppType === 'LI' && this.coldAppType === 'GA') // 5 params
        || (this.warmAppType === 'GA' && this.coldAppType === 'LI')) { // 5 params

        if (allEmptyParams > 2 || allEnteredParams < 5) {
          this.validationError = true;
          this.underdeterminedErrorNeedMoreParams = true;

        } else if ((warmEmptyParams + warmCalculatedParams) === 2 || (coldEmptyParams + coldCalculatedParams) === 2) {
          this.validationError = true;
          this.underdeterminedErrorDiffSides = true;

        } else if (tooManyParams < 2) {
          this.validationError = true;
          this.overdeterminedErrorTooManyParams = true;
        };

      } else if ((this.warmAppType === 'CO' && this.coldAppType === 'LI') // 5 params
        || (this.warmAppType === 'CO' && this.coldAppType === 'GA')) { // 6 params

        if (allEmptyParams > 2 || allEnteredParams < 5) {
          this.validationError = true;
          this.underdeterminedErrorNeedMoreParams = true;

        } else if ((warmEmptyParams + warmCalculatedParams) === 2 || (coldEmptyParams + coldCalculatedParams) === 2) {
          this.validationError = true;
          this.underdeterminedErrorDiffSides = true;

        } else if (tooManyParams < 1 || tooManyParams === 1) {
          this.validationError = true;
          this.overdeterminedErrorTooManyParams = true;
        };

      } else if ((this.warmAppType === 'LI' && this.coldAppType === 'EV') // 5 params
        || (this.warmAppType === 'GA' && this.coldAppType === 'EV')) { // 6 params

        if (allEmptyParams > 2 || allEnteredParams < 6) {
          this.validationError = true;
          this.underdeterminedErrorNeedMoreParams = true;

        } else if ((warmEmptyParams + warmCalculatedParams) === 2 || (coldEmptyParams + coldCalculatedParams) === 2) {
          this.validationError = true;
          this.underdeterminedErrorDiffSides = true;

        } else if (tooManyParams < 1 || tooManyParams === 1) {
          this.validationError = true;
          this.overdeterminedErrorTooManyParams = true;
        };

      } else if (this.warmAppType === 'CO' && this.coldAppType === 'EV') { // 6 params

        if (allEmptyParams > 2 || allEnteredParams < 6) {
          this.validationError = true;
          this.underdeterminedErrorNeedMoreParams = true;

        } else if ((warmEmptyParams + warmCalculatedParams) === 2 || (coldEmptyParams + coldCalculatedParams) === 2) {
          this.validationError = true;
          this.underdeterminedErrorDiffSides = true;

        } else if (tooManyParams < 1 || tooManyParams === 1) {
          this.validationError = true;
          this.overdeterminedErrorTooManyParams = true;
        };

      };

    };

    if (this.validationError) {
      this.enteredParamsValidated.emit({ valid: false });
    } else {
      this.enteredParamsValidated.emit({ valid: true });
    };

  };

  private vmValidationAfterCalculation() {
    // Reset flags
    this.vmResetAllFlags();

    if (!this.parametersCalculated.warm || !this.parametersCalculated.cold) {
      return;
    }

    if (this.parametersCalculated.warm.massFlowRate.value.commonUnits < 0) {
      this.validationError = true;
      this.warmMassFlowRateError = true;
    };

    if (this.parametersCalculated.cold.massFlowRate.value.commonUnits < 0) {
      this.validationError = true;
      this.coldMassFlowRateError = true;
    };

    const warmParams = this.parametersCalculated.warm;
    const coldParams = this.parametersCalculated.cold;

    let maxWarmTemp: number = null;
    let maxColdTemp: number = null;

    let maxWarmPressure: number = null;
    let maxColdPressure: number = null;

    // Find max temperature and pressure for Warm app
    if (this.warmAppType === 'LI' || this.warmAppType === 'GA') {
      maxWarmTemp = Math.max.apply(Math, [warmParams.inletTemp.value.commonUnits, warmParams.outletTemp.value.commonUnits]);
      if (this.warmAppType === 'GA'){
        maxWarmPressure = warmParams.inletPressure.value.commonUnits;
      }else{
        maxWarmPressure = 0;
      }
    } else if (this.warmAppType === 'CO') {
      maxWarmTemp = Math.max.apply(Math, [warmParams.satTemp.value.commonUnits, warmParams.outletTemp.value.commonUnits]);
      maxWarmPressure = warmParams.satPressure.value.commonUnits;
    } else if (this.warmAppType === 'EV') {
      maxWarmTemp = Math.max.apply(Math, [warmParams.inletTemp.value.commonUnits, warmParams.satTemp.value.commonUnits]);
      maxWarmPressure = 0;
    };

    // Find max temperature for Cold app
    if (this.coldAppType === 'LI' || this.coldAppType === 'GA') {
      maxColdTemp = Math.max.apply(Math, [coldParams.inletTemp.value.commonUnits, coldParams.outletTemp.value.commonUnits]);
      if (this.coldAppType === 'GA'){
        maxColdPressure = coldParams.inletPressure.value.commonUnits;
      }else{
        maxColdPressure = 0;
      }
    } else if (this.coldAppType === 'CO') {
      maxColdTemp = Math.max.apply(Math, [coldParams.satTemp.value.commonUnits, coldParams.outletTemp.value.commonUnits]);
      maxColdPressure = 0;
    } else if (this.coldAppType === 'EV') {
      maxColdTemp = Math.max.apply(Math, [coldParams.inletTemp.value.commonUnits, coldParams.satTemp.value.commonUnits]);
      maxColdPressure = coldParams.satPressure.value.commonUnits;
    };


    // Check if max temperature of warm/cold app is valid
    if (maxWarmTemp > this.designParameters.warm.max_temperature) {
      this.validationError = true;
      this.notValidWarmMaxTemperature = true;
    };
    if (maxColdTemp > this.designParameters.cold.max_temperature) {
      this.validationError = true;
      this.notValidColdMaxTemperature = true;
    };

    // Check if max pressure of warm/cold app is valid
    // Note: we subtract 100000 Pa because deesign pressure is gauge pressure and entered pressure is absolute pressure
    if (maxWarmPressure - 100000 > this.designParameters.warm.max_pressure) {
      this.validationError = true;
      this.notValidWarmMaxPressure = true;
    };
    if (maxColdPressure - 100000 > this.designParameters.cold.max_pressure) {
      this.validationError = true;
      this.notValidColdMaxPressure = true;
    };

    if (!this.extraDuty) {

      // Validation rules for some applications
      if (this.warmAppType === 'LI' || this.warmAppType === 'GA') {
        const fluidTemperatures = warmParams.fluidProps['specific_heat_cap'].map((_x) => _x.dependency1Value);
        const fluidMinTemp = Math.min.apply(Math, fluidTemperatures);
        const fluidMaxTemp = Math.max.apply(Math, fluidTemperatures);

        const inletTemp = warmParams.inletTemp.value.commonUnits;
        const outletTemp = warmParams.outletTemp.value.commonUnits;

        if ((inletTemp < fluidMinTemp || inletTemp > fluidMaxTemp) || (outletTemp < fluidMinTemp || outletTemp > fluidMaxTemp)) {
          // this.validationWarning = true;
          this.warmFluidTempNotInRangeWarning = true;
        };
      } else if (this.warmAppType === 'CO') {
        const fluidTemperatures = warmParams.fluidProps['sat_pressure'].map((_x) => _x.dependency1Value);
        const fluidMinTemp = Math.min.apply(Math, fluidTemperatures);
        const fluidMaxTemp = Math.max.apply(Math, fluidTemperatures);

        const satTemp = warmParams.satTemp.value.commonUnits;
        const outletTemp = warmParams.outletTemp.value.commonUnits;

        if ((satTemp < fluidMinTemp || satTemp > fluidMaxTemp) || (outletTemp < fluidMinTemp || outletTemp > fluidMaxTemp)) {
          // this.validationWarning = true;
          this.warmFluidTempNotInRangeWarning = true;
        };
      };


      if (this.coldAppType === 'LI' || this.coldAppType === 'GA') {
        const fluidTemperatures = coldParams.fluidProps['specific_heat_cap'].map((_x) => _x.dependency1Value);
        const fluidMinTemp = Math.min.apply(Math, fluidTemperatures);
        const fluidMaxTemp = Math.max.apply(Math, fluidTemperatures);

        const inletTemp = coldParams.inletTemp.value.commonUnits;
        const outletTemp = coldParams.outletTemp.value.commonUnits;

        if ((inletTemp < fluidMinTemp || inletTemp > fluidMaxTemp) || (outletTemp < fluidMinTemp || outletTemp > fluidMaxTemp)) {
          // this.validationWarning = true;
          this.coldFluidTempNotInRangeWarning = true;
        };
      };

      // Validation rules for different application combinations
      // 1. Application Liquid/Liquid
      // 2. Application Condensation/Liquid
      // 3. Application Liquid/Evaporation
      if ((this.warmAppType === 'LI' && this.coldAppType === 'LI')
        || (this.warmAppType === 'GA' && this.coldAppType === 'GA')
        || (this.warmAppType === 'LI' && this.coldAppType === 'GA')
        || (this.warmAppType === 'GA' && this.coldAppType === 'LI')) {
        // Warm side
        const warmInletTemp = warmParams.inletTemp.value.commonUnits;
        const warmOutletTemp = warmParams.outletTemp.value.commonUnits;
        const warmMaxPressureDrop = warmParams.maxPressureDrop.value.commonUnits;

        // Cold side
        const coldInletTemp = coldParams.inletTemp.value.commonUnits;
        const coldOutletTemp = coldParams.outletTemp.value.commonUnits;
        const coldMaxPressureDrop = coldParams.maxPressureDrop.value.commonUnits;

        if ((coldInletTemp >= warmOutletTemp) || (coldOutletTemp >= warmInletTemp) || (coldInletTemp > warmInletTemp) || (coldInletTemp >= coldOutletTemp) || (warmOutletTemp >= warmInletTemp)) {
          this.validationError = true;
          this.notAcceptableTemperature = true;
        };

        // Max Pressure Drop
        if (warmMaxPressureDrop <= 0) {
          this.validationError = true;
          this.warmMaxPressureDropError = true;
        };

        if (coldMaxPressureDrop <= 0) {
          this.validationError = true;
          this.coldMaxPressureDropError = true;
        };
      } else if ((this.warmAppType === 'CO' && this.coldAppType === 'LI')
        || (this.warmAppType === 'CO' && this.coldAppType === 'GA')) {

        const warmSatTemp: number = this.parametersCalculated.warm.satTemp.value.commonUnits;
        const warmOutletTemp: number = this.parametersCalculated.warm.outletTemp.value.commonUnits;

        const coldInletTemp: number = this.parametersCalculated.cold.inletTemp.value.commonUnits;
        const coldOutletTemp: number = this.parametersCalculated.cold.outletTemp.value.commonUnits;
        const coldMaxPressureDrop = coldParams.maxPressureDrop.value.commonUnits;

        if (coldInletTemp > warmOutletTemp) {
          this.validationError = true;
          this.validationsErrorsListOfTemp.push('Inlet temperature on the cold side cannot be higher than Outlet temperature on the warm side');
        } else if (coldOutletTemp > warmSatTemp) {
          this.validationError = true;
          this.validationsErrorsListOfTemp.push('Outlet temperature on the cold side cannot be higher than Saturation temperature');
        } else if (coldInletTemp > coldOutletTemp) {
          this.validationError = true;
          this.validationsErrorsListOfTemp.push('Inlet temperature on the cold side cannot be higher than Outlet temperature on the cold side');
        } else if (warmOutletTemp > warmSatTemp) {
          this.validationError = true;
          this.validationsErrorsListOfTemp.push('Outlet temperature on the warm side cannot be higher than Saturation temperature');
        };

        if (coldMaxPressureDrop <= 0) {
          this.validationError = true;
          this.coldMaxPressureDropError = true;
        };

      } else if ((this.warmAppType === 'LI' && this.coldAppType === 'EV')
        || (this.warmAppType === 'GA' && this.coldAppType === 'EV')) {
        const warmOutletTemp: number = this.parametersCalculated.warm.outletTemp.value.commonUnits;
        const warmInletTemp: number = this.parametersCalculated.warm.inletTemp.value.commonUnits;
        const warmMaxPressureDrop = warmParams.maxPressureDrop.value.commonUnits;

        const coldInletTemp: number = this.parametersCalculated.cold.inletTemp.value.commonUnits;
        const coldSatTemp: number = this.parametersCalculated.cold.satTemp.value.commonUnits;

        if (warmOutletTemp < coldInletTemp) {
          this.validationError = true;
          this.validationsErrorsListOfTemp.push('Outlet temperature on the warm side cannot be lower than Inlet temperature on the cold side');
        } else if (warmInletTemp < coldSatTemp) {
          this.validationError = true;
          this.validationsErrorsListOfTemp.push('Inlet temperature on the warm side cannot be lower than Saturation temperature');
        } else if (warmInletTemp < warmOutletTemp) {
          this.validationError = true;
          this.validationsErrorsListOfTemp.push('Outlet temperature on the warm side cannot be higher than Inlet temperature on the warm side');
        } else if (coldInletTemp > coldSatTemp) {
          this.validationError = true;
          this.validationsErrorsListOfTemp.push('Inlet temperature on the cold side cannot be higher than Saturation temperature');
        };

        if (warmMaxPressureDrop <= 0) {
          this.validationError = true;
          this.warmMaxPressureDropError = true;
        };
      } else if (this.warmAppType === 'CO' && this.coldAppType === 'EV') {
        const warmOutletTemp: number = this.parametersCalculated.warm.outletTemp.value.commonUnits;
        const warmSatTemp: number = this.parametersCalculated.warm.satTemp.value.commonUnits;

        const coldInletTemp: number = this.parametersCalculated.cold.inletTemp.value.commonUnits;
        const coldSatTemp: number = this.parametersCalculated.cold.satTemp.value.commonUnits;

        if (coldInletTemp > warmOutletTemp) {
          this.validationError = true;
          this.validationsErrorsListOfTemp.push('Inlet temperature on the cold side cannot be higher than Outlet temperature on the warm side');
        } else if (coldSatTemp > warmSatTemp) {
          this.validationError = true;
          this.validationsErrorsListOfTemp.push('Saturation temperature on the cold side cannot be higher than Saturation temperature on the warm side');
        } else if (coldInletTemp > coldSatTemp) {
          this.validationError = true;
          this.validationsErrorsListOfTemp.push('Inlet temperature on the cold side cannot be higher than Saturation temperature on the cold side');
        } else if (warmOutletTemp > warmSatTemp) {
          this.validationError = true;
          this.validationsErrorsListOfTemp.push('Outlet temperature on the warm side cannot be higher than Saturation temperature on the warm side');
        } else if (warmOutletTemp < coldInletTemp) {
          this.validationError = true;
          this.validationsErrorsListOfTemp.push('Outlet temperature on the warm side cannot be lower than Inlet temperature on the cold side');
        } else if (warmSatTemp < coldSatTemp) {
          this.validationError = true;
          this.validationsErrorsListOfTemp.push('Inlet temperature on the warm side cannot be lower than Saturation temperature on the warm side');
        } else if (warmSatTemp < warmOutletTemp) {
          this.validationError = true;
          this.validationsErrorsListOfTemp.push('Outlet temperature on the warm side cannot be higher than Saturation temperature on the warm side');
        } else if (coldInletTemp > coldSatTemp) {
          this.validationError = true;
          this.validationsErrorsListOfTemp.push('Inlet temperature on the cold side cannot be higher than Saturation temperature');
        };
      };

      // Minimum overdesign
      if (!this.overdesign && this.overdesign !== 0) {
        this.validationError = true;
        this.notValidOverdesign = true;
      };

    } else {
      if ((this.warmAppType === 'LI' && this.coldAppType === 'LI')
        || (this.warmAppType === 'GA' && this.coldAppType === 'GA')
        || (this.warmAppType === 'LI' && this.coldAppType === 'GA')
        || (this.warmAppType === 'GA' && this.coldAppType === 'LI')) {
        const warmInletTemp: number = warmParams.inletTemp.value.commonUnits;
        const coldInletTemp: number = coldParams.inletTemp.value.commonUnits;

        if (warmInletTemp <= coldInletTemp) {
          this.validationError = true;
          this.notValidInletTemperatures = true;
        }
        ;
      } else if ((this.warmAppType === 'CO' && this.coldAppType === 'LI')
        || (this.warmAppType === 'CO' && this.coldAppType === 'GA')) {
        const warmOutletTemp: number = warmParams.outletTemp.value.commonUnits;
        const coldInletTemp: number = coldParams.inletTemp.value.commonUnits;

        if (warmOutletTemp <= coldInletTemp) {
          this.validationError = true;
          this.notValidInletOutletTemperaturesCond = true;
        }

      } else if ((this.warmAppType === 'LI' && this.coldAppType === 'EV')
        || (this.warmAppType === 'GA' && this.coldAppType === 'EV')) {
        const warmInletTemp: number = warmParams.inletTemp.value.commonUnits;
        const coldSatTemp: number = coldParams.satTemp.value.commonUnits;

        if (warmInletTemp <= coldSatTemp) {
          this.validationError = true;
          this.notValidInletOutletTemperaturesEvap = true;
        }
      }
    }
    ;


    if (this.validationError) {
      this.calculatedParamsValidated.emit({ valid: false });
    } else {
      this.calculatedParamsValidated.emit({ valid: true });
    };

  };

  private vmResetAllFlags(): void {

    // Reset flags
    this.validationError = false;
    this.liquidNotSelectedErrorMsg = null;
    this.underdeterminedErrorNeedMoreParams = false;
    this.underdeterminedErrorDiffSides = false;
    this.overdeterminedErrorTooManyParams = false;
    this.saturationParamsNotEntered = false;
    this.outletTempWarmNotEntered = false;
    this.warmMassFlowRateError = false;
    this.warmFluidTempNotInRangeWarning = false;
    this.warmMaxPressureDropError = false;
    this.coldMassFlowRateError = false;
    this.coldFluidTempNotInRangeWarning = false;
    this.coldMaxPressureDropError = false;
    this.notAcceptableTemperature = false;
    this.notValidOverdesign = false;
    this.inletPressureWarmNotEntered = false;
    this.inletPressureColdNotEntered = false;
    this.notValidColdMaxTemperature = false;
    this.notValidWarmMaxTemperature = false;
    this.notValidInletTemperatures = false;
    this.notValidInletOutletTemperaturesCond = false;
    this.notValidInletOutletTemperaturesEvap = false;
    this.notValidColdMaxPressure = false;
    this.notValidWarmMaxPressure = false;
    this.validationsErrorsListOfTemp = [];
  };

}
