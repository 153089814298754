export interface LoginModel {
  username: string;
  password: string;
};

export interface AuthenticatedResponse {
  token: string;
  name: string;
  surname: string;
  photo: any;
};

export interface User extends AuthenticatedResponse {
  roles: string[];
  idLang?: string;
  idCurrency?: string;
  unitSystem?: string;
  defaultCountry?: string;
};
export interface IVatCompany {
  id: string;
  ownerId: any;
  name: string;
  domain: string;
  website: string;
  description: string;
  country: string;
  city: string
  kundenNr: any;
  zip: string;
  address: string;
  phone: string;
  createdate: string;
  ustid: any;
  routeBasePath: string;
  isNameValue: boolean;
};

export enum Role {
  UserAdmin = 'User Admin',
  Administrator = 'Administrator',
  User = 'User'
};

export interface IRegistrationCredentials {
  name: string,
  surname: string,
  mail: string,
  phone: string,
  dateBirthday?: string,
  jobPosition?: string,
  securityData: string,
  companyName: string,
  city: string;
  vatNumber: string,
  contactDetails?: string,
  discount: number,
  market: string,
  webAddress: string,
  idLang: string,
  country: number,
  postcode: any,
  address: any,
  CrmId: any
};

export interface IRegistrationSimpleCredentials {
  name: string,
  surname: string,
  mail: string,
  companyName: string,
  idLang: string,
  captchaResponse: string
};

export interface ICompany {
  companyId: number;
  name: string;
  vatNumber: string;
  address: string;
  city: string;
  postcode: string;
  countryId: number;
  market: string;
  webAddress: string;
  crmId: string;
  discount: number;
}

export interface IAutoSelectedOption {
  modelId: number;
  modelName: string;
  tubeDesign: string;
  tubeDesignName?: string;
  orientation: string;
  orientationName?: string;
  length: number;
  width: number;
  height: number;
  weight: number;
  shellDiameter: number;
  area: number;
  excessArea: number;
  pressureDropTubeSide: number;
  pressureDropShellSide: number;
  price: number;
  deliveryTimeDays: number;
  deliveryTime: string|number;
  sort?: any;
  id: string;
  heType: string;
};

export interface ITranslation {
  translationId: number;
  value: string;
  languageId: string;
  dateInsert: string;
  userInsert: number;
  userUpdate: number;
  dateUpdate: string;
  isActive: number;

};
export interface IPhysicalUnit {
  physicalUnitId: number;
  unitNameId: number;
  unitSign: number;
  addFactor: number;
  multFactor: number;
  isActive: number | boolean;
  dateInsert: string;
  userInsert: number;
  dateUpdate: string;
  userUpdate: number;
}

export interface IFluid {
  fluidId: number;
  fluidClassId: number;
  fluidNameId: number;
  fluidDescId: number;
  isCustom: number;
  isActive: number;
  fluidName?: string;
  fluidDesc?: string;
  customName?: string;
};

export interface IFluidClass {
  fluidClassId: number;
  parentClassId: number;
  classNameId: number;
  isActive: number;
  className?: string;
};
export interface IQuotation {
  quotationId: number,
  quotationNumber: string,
  quotationTitle: string,
  quotationStatus: string,
  customerId: number,
  idCurrency: string,
  idLang: string,
  dateInsert: string,
  userInsert: number,
  dateUpdate: string,
  userUpdate: number,
};
export interface IQuotationSummary extends IQuotation {
  items: IQuotationItemSummary[];
  priceSummary: number;
  dateLastEdit: string;
  dateCreated: string;
  open: boolean;
};
export interface IQuotationItemSummary {
  availability: string,
  unit_price: number,
  last_edit: string,
  itemId: number,
  mainSolutionId: number;
  itemNumber: string,
  quotationId: number,
  itemType: string,
  itemTitle: string,
  itemStatus: string,
  dateInsert: string,
  userInsert: number,
  dateUpdate: string,
  userUpdate: number,
  itemQuantity: number,
  itemDescription: string,
  spinner?: boolean // not returned from API
}

export interface INewQuotationCreatedResp {
  quotationId: number;
  quotationNumber: string;
  quotationStatus: string;
  idLang: string; // UPPERCASE
  idCurrency: string; // UPPERCASE
};
export interface INewItemResponse {
  itemId: number;
  itemNumber: string;
  quotationId: number;
  itemType: string;
  itemTitle: string;
  itemStatus: string;
  dateInsert: string;
  userInsert: number;
  dateUpdate: string;
  userUpdate: number;
};

export interface INewRequirementsResponse {
  requirementId: number;
  itemId: number;
  requirementType: string;
  requirementValue: any;
  dateInsert: string;
  userInsert: number;
  dateUpdate: string;
  userUpdate: number;
  name?: string;
};

export interface IUserProfile {
  userId: number;
  surname: string;
  name: string;
  mail: string;
  signActivity: string;
  phone: string;
  dateBirthday: string;
  idCompany: number;
  jobPosition: string;
  userInsert: number;
  userUpdate: number;
  dateInsert: string;
  dateUpdate: string;
};
export interface ISubuser {
  userId: number;
  surname: string;
  name: string;
  signActivity: string;
  dateBirthday: string;
  idCompany?: number;
  phone: string;
};

export interface INews {
  newsId: number;
  newsDate: string;
  newsDateLocal: string;
  idLang: string;
  newsTitle: string;
  newsText: string;
  newsImage: any;
};
export interface ICountryResp {
  countryId: number;
  countryCode: string;
  countryNameId: number;
  isActive: number|boolean;
  regulatory: string;
};

export interface IAdminCountry extends ICountryResp {
  phoneCode: string;
  dateInsert?: string;
  userInsert?: number;
  dateUpdate?: string;
  userUpdate?: number;
}

// *** Solution START ***
export interface ISolution {
  solutionId: number
  solutionType: string
  itemId: number
  price: number
  solutionData: SolutionData
  dateInsert: string
  userInsert: number
  dateUpdate: string
  userUpdate: number
}
/** For `Solution` */
interface SolutionData {
  type: string
  mechanical_data: MechanicalData
}
/** For `Solution` */
interface MechanicalData {
  TemaType: string
  LMTD: number
  LMTDCorrection: number
  NumberOfBaffles: number
  TubeSideHeatTransferCoefficient: number
  ShellSideHeatTransferCoefficient: number
  TubeSidePressureDrop: number
  ShellSidePressureDrop: number
  NumberOfShellSidePasses: number
  NumberOfTubeSidePasses: number
  TubeDesign: number
  Orientation: number
  OrientationName: string
  BaffleLayout: BaffleLayout
  TubeLayout: TubeLayout
  InletPortTubeSideInnerDiameter: number
  InletPortShellSideInnerDiameter: number
  OutletPortTubeSideInnerDiameter: number
  OutletPortShellSideInnerDiameter: number
  InletPortTubeSideOuterDiameter: number
  InletPortShellSideOuterDiameter: number
  OutletPortTubeSideOuterDiameter: number
  OutletPortShellSideOuterDiameter: number
  InletPortTubeSideConnectionType: number
  OutletPortTubeSideConnectionType: number
  InletPortShellSideConnectionType: number
  OutletPortShellSideConnectionType: number
  InletPortTubeSideConnectionPN: string
  InletPortShellSideConnectionPN: string
  OutletPortTubeSideConnectionPN: string
  OutletPortShellSideConnectionPN: string
  ShellInnerDiameter: number
  ShellOuterDiameter: number
  ShellTubeID: number
  TubeInnerDiameter: number
  TubeOuterDiameter: number
  TubeMaterial: TubeMaterial
  ShellMaterial: ShellMaterial
  LengthMin: number
  LengthMax: number
  LengthStepSize: number
  LengthRequired: number
  TotalLength: number
  Length: number
  NumberOfTubeComponents: number
  TubeComponentLength: number
  Width: number
  Height: number
  Weight: number
  DeliveryTimeDays: number
  BaffleBoreholeDiameter: number
  PEDCategoryName: any
  Module: any
  Area: number
  ExcessArea: number
  HotSideOnShell: boolean
  HeatTransferCoeffcientInside: number
  ShortestLinksDistanceCenterInWindow: number
  TubesInUpperAndLowerWindow: number
  NumberOfTubeRowsInCrossZone: number
  NumberOfTubeRowsInEndZone: number
  TubeMaterialId: number
  ShellMaterialId: number
  HeadMaterialId: number
  TubesheetMaterialId: number
  SupportsMaterialId: number
  SteamDrumMaterialId: number
  TubeSideMaxTemperature: number
  TubeSideMaxPressure: number
  ShellSideMaxTemperature: number
  ShellSideMaxPressure: number
  ShellSideMinPortDiameterByVelocity: number
  MaxVelocityTube: number
  TubeSideMinPortDiameterByVelocity: number
  MainDuty: MainDuty
  ItemRequirements: ItemRequirements
  HotSide: HotSide
  ColdSide: ColdSide
  OverallHeatTransferCoefficient: number
  Name: string
  Components: Component[]
  OptionsPrice: number
  NetOptionsPrice: number
  DiscountPercent: number
  Price: number
  ID: number
  ExtraDuties: ExtraDuties
  ExtraDutiesResults: ExtraDutiesResults
  Warnings: any[]
  Frame: string
  Type: string
  PlateArrangement: string
  DesignCombination: string
  EffArea: number
  PressureDropRealOnHotSide: number
  PressureDropRealOnColdSide: number
}
/** For `Solution` */
interface BaffleLayout {
  TypeName: string
  ID: number
  Name: number
  BaffleSpacing: number
  DistanceToFirstBaffle: number
  BaffleCut: number
  BoreholeGap: number
  BaffleDiameter: number
  NumberOfSealingsStrips: number
  ShellAndTubeModelID: number
  ShellInnerDiameter: number
  ShellAndTubeModelName: string
  ShellSideInletPortDiameter: number
  BaffleGeometry: number
}
/** For `Solution` */
interface TubeLayout {
  ID: number
  TubeSheetDiameter: number
  TubeOuterDiameter: number
  LongitudinalPitch: number
  TransversePitch: number
  TubeArrangement: number
  BundleDiameter: number
  BundleDiameterMin: number
  BundleDiameterMax: number
  TubeCoordinates: any[]
  RowYCoordinates: any
  ShortestLinksDistanceCenter: number
  ShortestTubeToTubeDistance: number
  NumberOfTubes: number
  NumberOfBlindTubes: number
  ShellAndTubeModelID: number
  ShellInnerDiameter: number
  ShellModel: string
  LayoutSchemeName: string
  LayoutMap: any
  CenterRow: any
  NumberOfVerticalCorridors: number
  WidthOfVerticalCorridors: number
  NumberOfHorizontalCorridors: number
  WidthOfHorizontalCorridors: number
  DistCenterRowToShell: number
}
/** For `Solution` */
interface TubeMaterial {
  ID: number
  Name: string
  PricePrKg: number
  ThermalConductivity: ThermalConductivity
  ShellPortLimit: ShellPortLimit
}
/** For `Solution` */
interface ThermalConductivity { }
interface ShellPortLimit {
  $type: string
  ConstantValue: number
}
/** For `Solution` */
interface ShellMaterial {
  ID: number
  Name: string
  PricePrKg: number
  ThermalConductivity: ThermalConductivity
  ShellPortLimit: ShellPortLimit
}
/** For `Solution` */
interface MainDuty {
  FluidCold: FluidCold
  FluidHot: FluidHot
  MassFlowCold: number
  MassFlowHot: number
  InletTempCold: number
  InletTempHot: number
  OutletTempCold: number
  OutletTempHot: number
  HeatRate: number
  MaxHotSidePressureDrop: number
  MaxColdSidePressureDrop: number
  FoulingShellSide: number
  FoulingTubeSide: number
  ExcessAreaPercent: number
  WorkPressureHot: any
  WorkPressureCold: any
  $type: string
}
/** For `Solution` */
interface FluidCold {
  TypeName: string
  ID: number
  ClassID: number
  Name: string
  NameId: number
  VaporViscosity: any
  VaporThermalConductivity: any
  VaporSpecificHeatCapacity: any
  VaporDensity: any
  Viscosity: Viscosity
  ThermalConductivity: ThermalConductivity
  EnthalpySteam: any
  EnthalpyCondensate: any
  Density: Density
  SpecificHeatCapacity: SpecificHeatCapacity
  SatPressure: any
}
/** For `Solution` */
interface Viscosity { }
/** For `Solution` */
interface Density { }
/** For `Solution` */
interface SpecificHeatCapacity { }
/** For `Solution` */
interface FluidHot {
  TypeName: string
  ID: number
  ClassID: number
  Name: string
  NameId: number
  VaporViscosity: any
  VaporThermalConductivity: any
  VaporSpecificHeatCapacity: any
  VaporDensity: any
  Viscosity: Viscosity
  ThermalConductivity: ThermalConductivity
  EnthalpySteam: any
  EnthalpyCondensate: any
  Density: Density
  SpecificHeatCapacity: SpecificHeatCapacity
  SatPressure: any
}
/** For `Solution` */
interface ItemRequirements {
  CountryId: number
  Regulatory: number
  DesignParameters: DesignParameters
  HEType: string
  Application: Application
  Cleanability: Cleanability
  SizeRestrictions: SizeRestrictions
}
/** For `Solution` */
interface DesignParameters {
  cold: Cold
  warm: Warm
}
/** For `Solution` */
interface Cold {
  max_temperature: number
  max_pressure: number
}
/** For `Solution` */
interface Warm {
  max_temperature: number
  max_pressure: number
}
/** For `Solution` */
interface Application {
  warm: string[]
  cold: string[]
}
/** For `Solution` */
interface Cleanability {
  warm: boolean
  cold: boolean
}
/** For `Solution` */
interface SizeRestrictions {
  Length: number
  Width: number
  Height: number
}
/** For `Solution` */
interface HotSide {
  HeatTransferCoefficient: number
  WorkFluid: any
}
/** For `Solution` */
interface ColdSide {
  HeatTransferCoefficient: number
  WorkFluid: any
}
/** For `Solution` */
interface Component {
  $type: string
  Length?: number
  weightType: number
  StComponentObj: StComponentObj
  Name: string
  Model: string
  Quantity: number
  PriceTag: PriceTag
  weightValue: number
  NumberOfBoreholes?: number
  BoroholeDiameter?: number
}
/** For `Solution` */
interface StComponentObj {
  $type: string
  Material_name_id?: number
  Id: number
  ShellTubeId?: number
  OuterDiameter?: number
  InnerDiameter?: number
  IsActive?: number
  DateInsert?: string
  UserInsert?: number
  DateUpdate?: string
  UserUpdate?: number
  RawMaterialId: number
  Name: string
  PriceTagId: number
  WeightValue: number
  BaffleId?: number
  Diameter?: number
  Thickness?: number
  HeadId?: number
  PipeInnerDiameter?: number
  ConnectionId?: number
  ConnectionTypeDataId?: number
  PressureRatingID?: string
  ConnectionTypeID?: number
  ConnectionTypeNameID?: number
  MaxPressure?: number
  BodyFlangeId?: number
  ShellOuterDiameter?: number
  RawThickness?: number
  FinalThickness?: number
  NumberOfBolts?: number
  BoltHoleDiameter?: number
  Temperature?: number
  StTlId?: number
  TubesheetId?: number
}
/** For `Solution` */
interface PriceTag {
  price_tag_id: number
  price_tag_number: string
  price_type: string
  currency_id: string
  price_value: number
  is_active: string
  PriceType: number
}
/** For Solution */
interface ExtraDuties {
  [key: string]: {
    $type: string
    Name: string
    SaturationPressure: number
    SubcoolingRequired: boolean
    TemperatureCrossover: boolean
    FluidCold: FluidCold
    FluidHot: FluidHot
    MassFlowCold: number
    MassFlowHot: number
    InletTempCold: number
    InletTempHot: number
    OutletTempCold: number
    OutletTempHot: number
    HeatRate: number
    MaxHotSidePressureDrop: number
    MaxColdSidePressureDrop: number
    FoulingShellSide: number
    FoulingTubeSide: number
    ExcessAreaPercent: number
    WorkPressureHot: number
    WorkPressureCold: any
  }
}
interface ExtraDutiesResults {
  [key: string]: {
    $type: string
    HeatTransferCoefficientCondensation: number
    HeatTransferCoeffcientInside: number
    LengthRequired: number
    LMTD: number
    LMTDCorrection: number
    ShellSidePressureDrop: number
    TubeSidePressureDrop: number
    ShellSideHeatTransferCoefficient: number
    TubeSideHeatTransferCoefficient: number
    OverallHeatTransferCoefficient: number
    Area: any
    ExcessArea: any
    Warnings: Warning[]
  }
}
interface Warning {
  Type: string
  Message: string
}
// *** Solution END ***

export interface ISolutionConfigComponents {
  FrontHeadPortPipeIn: ISolutionConfigComponent;
  FrontHeadPortPnIn: ISolutionConfigComponent;
  FrontHeadPortFlangeIn: ISolutionConfigComponent;
  HeadPortPipeOut: ISolutionConfigComponent;
  HeadPortPnOut: ISolutionConfigComponent;
  HeadPortFlangeOut: ISolutionConfigComponent;
  ShellPortPipeIn: ISolutionConfigComponent;
  ShellPortPnIn: ISolutionConfigComponent;
  ShellPortFlangeIn: ISolutionConfigComponent;
  ShellPortPipeOut: ISolutionConfigComponent;
  ShellPortPnOut: ISolutionConfigComponent;
  ShellPortFlangeOut: ISolutionConfigComponent;
  HeadType: ISolutionConfigComponent;
  //InsulationThickness: ISolutionConfigComponent;
  InsulationMaterial: ISolutionConfigComponent;
  InsulationCover: ISolutionConfigComponent;
  LiftingLugs: ISolutionConfigComponent;
  Elbow: ISolutionConfigComponent;
  CondensateBottle: ISolutionConfigComponent;
  NotifiedBody: ISolutionConfigComponent;
  DrainVent: ISolutionConfigComponent;
  SurfaceWarm: ISolutionConfigComponent;
  SurfaceCold: ISolutionConfigComponent;
  Feet: ISolutionConfigComponent;
  Gaskets: ISolutionConfigComponent;
  PortPipeSafetyValve?: ISolutionConfigComponent;
  PortPipeDrain?: ISolutionConfigComponent;
  PortPipeTemp?: ISolutionConfigComponent;
  PortPipePressure?: ISolutionConfigComponent;
  PortPipeLevelIndicator?: ISolutionConfigComponent;
  PortPipeDesalination?: ISolutionConfigComponent;
  PortPnSafetyValve?: ISolutionConfigComponent;
  PortPnDrain?: ISolutionConfigComponent;
  PortPnTemp?: ISolutionConfigComponent;
  PortPnPressure?: ISolutionConfigComponent;
  PortPnLevelIndicator?: ISolutionConfigComponent;
  PortPnDesalination?: ISolutionConfigComponent;
  PortFlangeSafetyValve?: ISolutionConfigComponent;
  PortFlangeDrain?: ISolutionConfigComponent;
  PortFlangeTemp?: ISolutionConfigComponent;
  PortFlangePressure?: ISolutionConfigComponent;
  PortFlangeLevelIndicator?: ISolutionConfigComponent;
  PortFlangeDesalination?: ISolutionConfigComponent;

};
export interface ISolutionConfigComponent {
  visible: boolean;
  enabled: boolean;
  selectedValue: number | string | boolean;
  componentName?: string; // API not return this property.
  options: {
    id: number | string | boolean;
    name: string | number;
    translationId?: number; // API may not return this property.
    image?: any; // API does not return this property
  }[];
}

// Zilo Hints

export interface IZiloHint {
  ziloHintId: number;
  itemStep: string;
  hintTextId: number;
  isActive: number;
};

export interface IZiloHintExtended extends IZiloHint {
  dateInsert: string;
  dateUpdate: string;
  userInsert: number;
  userUpdate: number;
};

// *** ST Models ***
export interface IStModel {
  stModelId: number,
  numberOfShellSidePasses: number,
  numberOfTubeSidePasses: number,
  name: string,
  tubeDesign: string,
  admissibleOrientations: string,
  baffleOrientation: string,
  temaType: string,
  shellInnerDiameterMin: number,
  shellInnerDiameterMax: number,
  lengthMin: number,
  lengthMax: number,
  isActive: string,
  dateInsert: string,
  dateUpdate: string,
  userInsert: number,
  userUpdate: number,
  shellBundleMinClearanceAbsolute: number,
  shellBundleMaxClearanceAbsolute: number,
  shellBundleMinClearanceInTransversepitch: number,
  shellBundleMaxClearanceInTransversepitch: number,
  tubesheetsQuantity: number,
  tubesheetsAsBodyFlange: boolean,
  priceFactor: number
}

// *** ST TUBE LAYOUTS START ***
export interface IStTubeLayout {
  tubeLayoutId: number;
  stModelId: number;
  shellInnerDiameter: number;
  tubeSheetDiameter: number;
  tubeOuterDiameter: number;
  longitudinalPitch: number;
  transversePitch: number;
  tubeArrangement: number;
  bundleDiameter: number;
  shortestLinksDistanceCenter: number;
  shortestTubeToTubeDistance: number;
  tubePlan: string | any;
  numberOfTubes: number;
  numberOfBlindTubes: number;
  isActive: number | boolean;
  dateInsert?: string;
  userInsert?: number;
  dateUpdate?: string;
  userUpdate?: number;
  numberOfHorizontalCorridors: number;
  widthOfHorizontalCorridors: number;
  totalBundleBendingLength: number;
  bendingAreaLength: number;
  bendingLengths: string | any;
}
// *** ST TUBE LAYOUTS END ***

// *** ST BAFFLE LAYOUTS START ***
export interface IStBaffleLayout {
  baffleLayoutId: number;
  name: string;
  baffleGeometry: string;
  stModelId: number;
  shellInnerDiameter: number;
  baffleSpacing: number;
  distanceToFirstBaffle: number;
  baffleCut: number;
  boreholeGap: number;
  baffleDiameter: number;
  numberOfSealingsStrips: number;
  isActive: number | boolean;
  dateInsert?: string;
  userInsert?: number;
  dateUpdate?: string;
  userUpdate?: number;
  shellSidePortDiameter: number;
};
// *** ST BAFFLE LAYOUTS END ***
// *** ST TUBES IN WINDOW START ***
export interface IStTubeAndBaffleLayout {
  tubesInWindowId: number;
  baffleLayoutId: number;
  tubeLayoutId: number;
  tubesInUpperAndLowerWindow: number;
  isActive: number | boolean;
  dateInsert?: string;
  userInsert?: number;
  dateUpdate?: string;
  userUpdate?: number;
  numberOfTubeRowsInCrossZone: number;
  numberOfTubeRowsInEndZone: number;
};
// *** ST TUBES IN WINDOW END ***

// *** ST MODEL IMAGE START ***
export interface IStModelImages {
  stModelImageId?: number;
  stModelId: number;
  imageNumber: number;
  imagePath: string;
  isActive: number | boolean;
  dateInsert?: string;
  userInsert?: number;
  dateUpdate?: string;
  userUpdate?: number;
};
// *** ST MODEL IMAGE END ***
// *** ST DXF DRAWING START ***
export interface IStModelHead {
  stModelHeadId?: number;
  stModelId: number;
  headType: string;
  image: string;
  isActive: boolean | number;
  dateInsert?: string;
  userInsert?: number;
  dateUpdate?: string;
  userUpdate?: number;
};
// *** ST DXF DRAWING END ***
// *** ST DXF DRAWING START ***
export interface IStModelDrawing {
  stModelDrawingId?: number;
  stModelId: number;
  drawingType: string;
  drawing: string;
  isActive: number | boolean;
  dateInsert?: string;
  userInsert?: number;
  dateUpdate?: string;
  userUpdate?: number;
};
// *** ST DXF DRAWING END ***
// *** ST EQUIP RULE START ***
export interface IStModelEquipRule {
  stEquipRuleId?: number;
  stModelId: number;
  equipOrder: number;
  component: string;
  uiOnly: boolean;
  uiNeeded: boolean;
  uiVisible: boolean;
  uiEnabled: boolean;
  isActive: number | boolean;
  dateInsert?: string;
  userInsert?: number;
  dateUpdate?: string;
  userUpdate?: number;
};
// *** ST EQUIP RULE END ***
// *** ST SPECIAL APPLICATION START ***
export interface IStModelSpecialApplication {
  modelCustAppId?: number;
  stModelId: number;
  application: string;
  isActive: number | boolean;
  dateInsert?: string;
  userInsert?: number;
  dateUpdate?: string;
  userUpdate?: number;
};
// *** ST SPECIAL APPLICATION END ***
// *** ST FLUID STATES  START ***
export interface IStModelApplication {
  flAdmisId?: number;
  stModelId: number;
  usage: string;
  side: string;
  isActive: number | boolean;
  dateInsert?: string;
  userInsert?: number;
  dateUpdate?: string;
  userUpdate?: number;
};
export interface IDictionaryData {
  idDictionary: number;
  abrState: string;
  state: string;
  description: any;
  sortIndex: number;
  translationId: number;
  signActivity: number;
  dopParam1: any;
  dopParam2: any;
};
// *** ST MODEL FLUID STATES END ***

// *** Properties and Units start ***
export interface IPhysicalProperty {
  propertyNameId: number;
  propertyUnitId: number;
  propertyType: string;
  minAllowedValue: any;
  maxAllowedValue: any;
  dependency1: any;
  dependency2: any;
  calculationMethod: any;
  isActive: number | boolean;
  dateInsert: string;
  userInsert: number;
  dateUpdate: string;
  userUpdate: number;
  physicalPropertyId: string;
}
export interface IUnitUsage {
  unitUsageId: number;
  physicalUnitId: number;
  unitSystemId: number;
  physicalPropertyId: string;
  isActive: number | boolean;
  dateInsert?: string;
  userInsert?: number;
  dateUpdate?: string;
  userUpdate?: number;
}
export interface IUnitSystem {
  unitSystemId: number;
  systemNameId: number;
  systemAbreviation: string;
  isActive: boolean | number;
  dateInsert?: string;
  userInsert?: number;
  dateUpdate?: string;
  userUpdate?: number;
}

// *** Properties and Units end ***

// *** Shell and Tube start ***
export interface IHEProductType {
  idDictionary: number;
  abrState: string;
  state: string;
  description: any;
  sortIndex: number;
  translationId: number;
  signActivity: number;
  dopParam1: any;
  dopParam2: any;
  title?: string; // API didnt return this property
}
// *** Shell and Tube end ***

// *** Restricted Product Types start ***
export interface IRestrictedProductType {
  userId: number;
  codeObject: number;
  valueObject: string;
  accessType: string;
  dateInsert: string;
  userInsert: number;
  dateUpdate: string;
  userUpdate: number;
}
// *** Restricted Product Types end ***

