<!-- header start -->
<app-header>
  <app-usermenu></app-usermenu>
</app-header>
<!-- header end -->

<!-- duty component start -->
<div class="duty-comp">
  <!-- main container start -->
  <div class="main-container">

    <!-- toolbar start -->
    <app-toolbar>
      <button class="back-btn" (click)="back()">Back</button>
      <button class="skip margin-left-auto" (click)="vmGoToSolution()" *ngIf="solutions?.length>0">Return to
        Solution</button>
    </app-toolbar>
    <!-- toolbar end -->

    <!-- progressbar start -->
    <app-progressbar [progressStep]="7"></app-progressbar>
    <!-- progressbar end -->

    <!-- main title start -->
    <div class="main-title">
      {{'dutyPage.title'|translate}}
    </div>
    <!-- main title end -->


    <!-- calculation section start -->
    <div class="calculation-section" [ngClass]="{'disabled-overlay': quotationStatus}">
      <!-- main container start -->
      <div class="main-container">
        <!-- content container start -->
        <div class="content-container" *ngIf="unitSystems">

          <div class="side warm-side" [ngSwitch]="requirementApplication?.requirementValue.warm[0]">

            <!-- title start -->
            <div class="title">{{'commonLabels.warmSide'|translate}}</div>
            <!-- title end -->

            <app-liquid *ngSwitchCase="'LI'" [side]="'warm'"
              [liquidType]="requirementApplication.requirementValue.warm[0]"
              [existingParams]="existingRequirementForWarmSide" [heatExchangeRate]="heatExchangeRate"
              [validation]="enteredParamsValidated" (parametersEntered)="onSideParametersEntered('warm',$event)"
              (parametersCalculated)="onSideParametersCalculated('warm',$event)">
            </app-liquid>

            <app-gas *ngSwitchCase="'GA'" [side]="'warm'" [liquidType]="requirementApplication.requirementValue.warm[0]"
              [existingParams]="existingRequirementForWarmSide" [heatExchangeRate]="heatExchangeRate"
              [validation]="enteredParamsValidated" (parametersEntered)="onSideParametersEntered('warm',$event)"
              (parametersCalculated)="onSideParametersCalculated('warm',$event)">
            </app-gas>

            <app-condensation *ngSwitchCase="'CO'" [side]="'warm'"
              [liquidType]="requirementApplication.requirementValue.warm[0]"
              [existingParams]="existingRequirementForWarmSide" [heatExchangeRate]="heatExchangeRate"
              [validation]="enteredParamsValidated" [application]="requirementApplication"
              (parametersEntered)="onSideParametersEntered('warm',$event)"
              (parametersCalculated)="onSideParametersCalculated('warm',$event)">
            </app-condensation>
          </div>

          <!-- middle column start -->
          <div class="middle-column">

            <!-- heat exchange rate start -->
            <div class="result-block">

              <!-- label start -->
              <label for="heatExchangeRate" class="result-block-label">{{'dutyPage.heatExchangeRate'|translate}}</label>
              <!-- label end -->

              <!-- parameter input block start -->
              <div class="result-block-input-block" [ngClass]="{'calculated': heatExchangeRate.calculated}">

                <!-- input field start -->
                <input class="result-block-input-field" type="number" name="heatExchangeRate" id="heatExchangeRate"
                  [ngModel]="heatExchangeRate.value.userUnits"
                  (ngModelChange)="onHEorOverdesignEnter('heatExchangeRate',$event)">
                <!-- input field end -->

                <!-- parameter-unit-select start -->
                <select name="unit" id="unit" class="parameter-unit-select" [ngModel]="heatExchangeRate.physicalUnitId"
                  (ngModelChange)="onUnitChange($event)">
                  <option *ngFor="let unit of unitSystems.units.heat_exchange_rate" [ngValue]="unit.physicalUnitId">
                    {{unit.unitSign}}</option>
                </select>
                <!-- parameter-unit-select end -->

              </div>
              <!-- parameter input block end -->

            </div>
            <!-- heat exchange rate end -->

            <!-- minimum overdesign start -->
            <div class="result-block">

              <!-- label start -->
              <label for="overdesign" class="result-block-label">{{'dutyPage.overdesign'|translate}}</label>
              <!-- label end -->

              <!-- parameter input block start -->
              <div class="result-block-input-block">

                <!-- input field start -->
                <input class="result-block-input-field" type="number" name="overdesign" id="overdesign"
                  [ngModel]="overdesign" (ngModelChange)="onHEorOverdesignEnter('overdesign',$event)">
                <!-- input field end -->

                <!-- parameter-unit-select start -->
                <select name="overdesignUnit" id="overdesignUnit" class="parameter-unit-select no-arrow">
                  <option value="%">%</option>
                </select>
                <!-- parameter-unit-select end -->

              </div>
              <!-- parameter input block end -->

            </div>
            <!-- minimum overdesign end -->

            <!-- btn-new start -->
            <button type="button" class="btn-continue" (click)="saveRequest()">{{'dutyPage.viewResults'|translate}}</button>
            <!-- btn-new end -->

          </div>
          <!-- middle column end -->

          <!-- cold side start -->
          <div class="side cold-side" [ngSwitch]="requirementApplication?.requirementValue.cold[0]">
            <!-- title start -->
            <div class="title">{{'commonLabels.coldSide'|translate}}</div>
            <!-- title end -->

            <app-liquid *ngSwitchCase="'LI'" [side]="'cold'"
              [liquidType]="requirementApplication.requirementValue.cold[0]"
              [existingParams]="existingRequirementForColdSide" [heatExchangeRate]="heatExchangeRate"
              [validation]="enteredParamsValidated" (parametersEntered)="onSideParametersEntered('cold',$event)"
              (parametersCalculated)="onSideParametersCalculated('cold',$event)">
            </app-liquid>
            <app-gas *ngSwitchCase="'GA'" [side]="'cold'" [liquidType]="requirementApplication.requirementValue.cold[0]"
              [existingParams]="existingRequirementForColdSide" [heatExchangeRate]="heatExchangeRate"
              [validation]="enteredParamsValidated" (parametersEntered)="onSideParametersEntered('cold',$event)"
              (parametersCalculated)="onSideParametersCalculated('cold',$event)">
            </app-gas>
            <app-evaporation *ngSwitchCase="'EV'" [side]="'cold'"
              [appType]="requirementApplication.requirementValue.cold[0]"
              [existingParams]="existingRequirementForColdSide" [heatExchangeRate]="heatExchangeRate"
              [validation]="enteredParamsValidated" (parametersEntered)="onSideParametersEntered('cold',$event)"
              (parametersCalculated)="onSideParametersCalculated('cold',$event)"></app-evaporation>
          </div>
          <!-- cold side end -->
        </div>
        <!-- content container end -->

        <!-- errors container start -->
        <div class="errors-container" *ngIf="requirementApplication">
          <app-duty-validation [warmAppType]="requirementApplication.requirementValue.warm[0]"
            [coldAppType]="requirementApplication.requirementValue.cold[0]" [parametersEntered]="parametersEntered"
            [parametersCalculated]="parametersCalculated" [heatExchangeRate]="heatExchangeRate"
            [overdesign]="overdesign" [designParameters]="designParameters"
            (enteredParamsValidated)="onEnteredParamsValidation($event)"
            (calculatedParamsValidated)="onCalculatedParamsValidation($event)"></app-duty-validation>
        </div>
        <!-- errors container end -->

      </div>
      <!-- main container end -->

    </div>
    <!-- calculation section end -->

  </div>
  <!-- main container end -->
</div>
<!-- duty component end -->

<!-- footer start -->
<app-footer></app-footer>
<!-- footer end -->

<!-- raw-material-popup start -->
<div class="popup-raw-material" *ngIf="materialsPopUpFlag">
  <!-- popup-content-container start -->
  <div class="popup-content-container">
    <!-- popup-title start -->
    <div class="popup-title">{{'dutyPage.materialSuggestion'|translate}}</div>
    <!-- popup-title end -->

    <!-- popup-selects-wrapper start -->
    <div class="popup-selects-wrapper">
      <!-- popup-side start -->
      <div class="popup-side warm" *ngIf="rawMaterials">
        <!-- popup-side-title start -->
        <div class="popup-side-title">{{'commonLabels.warmSide'|translate}}</div>
        <!-- popup-side-title end -->

        <select name="head" id="head" [(ngModel)]="warmSelectedMaterial">
          <option *ngFor="let material of rawMaterials" [ngValue]="material.rawMaterialId">{{material.title}}</option>
        </select>

      </div>
      <!-- popup-side end -->
      <!-- popup-side start -->
      <div class="popup-side cold" *ngIf="rawMaterials">
        <!-- popup-side-title start -->
        <div class="popup-side-title">{{'commonLabels.coldSide'|translate}}</div>
        <!-- popup-side-title end -->

        <select name="head" id="head" [(ngModel)]="coldSelectedMaterial">
          <option *ngFor="let material of rawMaterials" [ngValue]="material.rawMaterialId">{{material.title}}</option>
        </select>

      </div>
      <!-- popup-side end -->
    </div>
    <!-- popup-selects-wrapper end -->

    <!-- confirm-btn start -->
    <button type="button" class="confirm-btn" (click)="onMaterialsConfirm()">{{'commonLabels.confirm'|translate}}</button>
    <!-- confirm-btn end -->

  </div>
  <!-- popup-content-container end -->
</div>
<!-- raw-material-popup end -->

<!-- side menu settings start -->
<app-duty-side-menu (applicationChanged)="onApplicationOrDesignParamsChange($event,'application')"
  (designParameterChanged)="onApplicationOrDesignParamsChange($event,'design_parameters')" [disabled]="quotationStatus"></app-duty-side-menu>
<!-- side menu settings end -->

<!-- zilo-hints start -->
<app-zilo-hints [itemStatus]="7"></app-zilo-hints>
<!-- zilo-hints end -->
