<!-- accounts-management-comp start -->
<div class="accounts-management-comp">
  <!-- header start -->
  <app-header>
    <app-usermenu></app-usermenu>
  </app-header>
  <!-- header end -->

  <!-- main container start -->
  <div class="main-container">
    <!-- progressbar start -->
    <app-progressbar></app-progressbar>
    <!-- progressbar end -->

    <!-- main-content-container start -->
    <div class="main-content-container">

      <!-- buttons-container start -->
      <div class="buttons-container">
        <!-- add-btn start -->
        <button type="button" class="btn btn-add" (click)="addNewUser()">Add User</button>
        <!-- add-btn end -->
      </div>
      <!-- buttons-container end -->

      <!-- table-container start -->
      <div class="table-container">
        <!-- table start -->
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

          <!-- active(checkbox) column start -->
          <ng-container matColumnDef="active">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Active </th>
            <td mat-cell *matCellDef="let user">

              <mat-checkbox [color]="'primary'" [checked]="user.checked"
                (change)="onActiveCheckboxChange($event, user)">
              </mat-checkbox>

            </td>
          </ng-container>
          <!-- active(checkbox) column end -->

          <!-- name column start -->
          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> {{'userSettingsPage.fields.name' | translate}} </th>
            <td mat-cell *matCellDef="let user"> {{user.name}} </td>
          </ng-container>
          <!-- name column end -->

          <!-- surname column start -->
          <ng-container matColumnDef="surname">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> {{'userSettingsPage.fields.surname' | translate}} </th>
            <td mat-cell *matCellDef="let user"> {{user.surname}} </td>
          </ng-container>
          <!-- surname column end -->

          <!-- phone column start -->
          <ng-container matColumnDef="phone">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> {{'userSettingsPage.fields.phone' | translate}} </th>
            <td mat-cell *matCellDef="let user"> {{user.phone}} </td>
          </ng-container>
          <!-- phone column end -->

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onTableRowClick(row)"
            [ngClass]="{'row-is-clicked':selectedUser?.userId === row.userId,'row-not-active': !row.checked}">
          </tr>

        </table>
        <!-- table end -->
      </div>
      <!-- table-container end -->

      <!-- tabs-container start -->
      <div class="tabs-container" *ngIf="selectedUser">
        <!-- card start -->
        <mat-card>
          <!-- card content start -->
          <mat-card-content>
            <!-- tabs start -->
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
              <mat-tab label="Edit">
                <!-- registration form start -->
                <form class="edit-form">

                  <!-- field start -->
                  <div class="field">
                    <label for="name">{{'userSettingsPage.fields.name' | translate}}:</label>
                    <input type="text" name="name" id="name" [(ngModel)]="selectedUser.name" required>
                  </div>
                  <!-- field end -->

                  <!-- field start -->
                  <div class="field">
                    <label for="surname">{{'userSettingsPage.fields.surname' | translate}}:</label>
                    <input type="text" name="surname" id="surname" [(ngModel)]="selectedUser.surname" required>
                  </div>
                  <!-- field end -->

                  <!-- field start -->
                  <div class="field">
                    <label for="phone">{{'userSettingsPage.fields.phone' | translate}}:</label>
                    <input type="tel" name="phone" id="phone" [(ngModel)]="selectedUser.phone" required>
                  </div>
                  <!-- field end -->

                  <!-- field start -->
                  <div class="field">
                    <label for="jobPosition">Active:</label>
                    <mat-checkbox [name]="'active'" [color]="'primary'" [(ngModel)]="selectedUser.checked">
                    </mat-checkbox>
                  </div>
                  <!-- field end -->

                  <!-- buttons block start -->
                  <div class="buttons-block">
                    <!-- button start -->
                    <button type="submit" class="btn btn-cancel" (click)="onEditCancel()">{{'commonLabels.cancelButton'|translate}}</button>
                    <!-- button end -->
                    <!-- button start -->
                    <button type="submit" class="btn btn-register" (click)="onEditSave()">SAVE</button>
                    <!-- button end -->
                  </div>
                  <!-- buttons block end -->

                  <!-- validation block start -->
                  <div class="validation-block" *ngIf="validationError">
                    <!-- validation message start -->
                    <div class="validationMsg" *ngIf="emptyUserNameError">{{emptyUserNameMsg}}</div>
                    <!-- validation message end -->
                    <!-- validation message start -->
                    <div class="validationMsg" *ngIf="userExistError">The user with such name and surname already exists
                    </div>
                    <!-- validation message end -->
                  </div>
                  <!-- validation block end -->

                </form>
                <!-- registration form end -->
              </mat-tab>
            </mat-tab-group>
            <!-- tabs end -->
          </mat-card-content>
          <!-- card content end -->
        </mat-card>
        <!-- card end -->
      </div>
      <!-- tabs-container end -->

    </div>
    <!-- main-content-container end -->

  </div>
  <!-- main container end -->

</div>
<!-- accounts-management-comp end -->
