import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, switchMap } from 'rxjs';
import { ApiService, SnackBarService, StateService } from 'src/app/services';
import { INewItemResponse, INewRequirementsResponse, Role, User } from 'src/app/services/interfaces';

@Component({
  selector: 'app-assistant-cleanability',
  templateUrl: './assistant-cleanability.component.html',
  styleUrls: ['./assistant-cleanability.component.scss']
})
export class AssistantCleanabilityComponent implements OnInit {
  private quotationId: number = null;
  private itemId: number = null;
  private user: User = null;
  private item: INewItemResponse = null;
  private existingRequirement: INewRequirementsResponse = null;
  private applicationRequirement: INewRequirementsResponse = null;

  public warmParamValue: string = 'no';
  public coldParamValue: string = 'no';
  public quotationStatus: boolean = false;
  constructor(private stateService: StateService, private apiService: ApiService, private snackBarService: SnackBarService, private route: ActivatedRoute, private router: Router) { };

  public ngOnInit(): void {
    this.quotationId = +this.route.snapshot.params['quotationId'];
    this.itemId = +this.route.snapshot.params['itemId'];
    this.user = this.stateService.get('user');

    forkJoin({
      items: this.apiService.getItems(this.quotationId),
      itemReqs: this.apiService.getItemRequirements(this.itemId),
      quotationStatus: this.apiService.getQuotationStatus(this.quotationId)
    }).subscribe({
      next: ({ items, itemReqs, quotationStatus }) => {

        this.existingRequirement = itemReqs.find((_req) => _req.requirementType === 'cleanability');
        this.applicationRequirement = itemReqs.find((_req) => _req.requirementType === 'application');
        this.item = items.find((_x) => _x.itemId === this.itemId);

        this.quotationStatus = quotationStatus.quotationStatus === 'ordered';

        if (this.existingRequirement) {

          this.warmParamValue = this.existingRequirement.requirementValue.warm ? 'yes' : 'no';
          this.coldParamValue = this.existingRequirement.requirementValue.cold ? 'yes' : 'no';
        };

      },
      error: (error) => {
        this.snackBarService.open();
      }
    })
  };
  public back() {

    if (this.applicationRequirement.requirementValue.cold[0] === 'EV') {
      this.router.navigate([this.quotationId, this.itemId, 'evaporation_details'], { relativeTo: this.route.parent });
    } else if (this.applicationRequirement.requirementValue.warm[0] === 'CO') {
      this.router.navigate([this.quotationId, this.itemId, 'condensation_details'], { relativeTo: this.route.parent });
    } else {
      this.router.navigate([this.quotationId, this.itemId, 'application'], { relativeTo: this.route.parent });
    };

  };

  public skipGuide(): void {
    const observable = this.apiService.skipGuide(this.itemId).subscribe({
      next: (itemId: number) => {
        let url: string = null;
        if (this.user.roles.includes(Role.UserAdmin)) {
          url = 'useradmin/configurator';
        } else {
          url = 'user/configurator';
        }
        observable.unsubscribe();
        this.router.navigate([url, this.quotationId, this.itemId, 'duty']);
      },
      error: (error) => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    });
  };

  public onContinue() {
    const data = {
      warm: this.warmParamValue === 'yes' ? true : false,
      warm_fouling: null,
      cold: this.coldParamValue === 'yes' ? true : false,
      cold_fouling: null,
    }

    let observable = null;

    if (this.existingRequirement) {
      data.warm_fouling = this.existingRequirement.requirementValue?.warm_fouling || null;
      data.cold_fouling = this.existingRequirement.requirementValue?.cold_fouling || null;

      observable = this.apiService.updateItemRequirements(this.existingRequirement.requirementId, { requirementType: 'cleanability', requirementValue: data });
    } else {
      observable = this.apiService.saveItemRequirements(this.itemId, { requirementType: 'cleanability', requirementValue: data });
    };


    observable.pipe(switchMap(() => {
      return this.apiService.updateItem(this.itemId, { quotationId: this.quotationId, itemStatus: '5', itemType: 'ST', itemNumber: '1' });
    })).subscribe({
      next: () => {
        this.router.navigate([this.quotationId, this.itemId, 'restrictions'], { relativeTo: this.route.parent });
      },
      error: (error) => {
        this.snackBarService.open();
      }
    });

  }
}
